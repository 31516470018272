import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import customersReducer from "./customers/customers.reducer";
import dobExpenseReducer from "./dob-expense/dob-expense.reducer";
import dobReducer from "./dob/dob.reducer";
import driversReducer from "./drivers/drivers.reducer";
import expenseTypesReducer from "./expense-types/expense-types.reducer";
import expensesReducer from "./expenses/expenses.reducer";
import farmsReducer from "./farms/farms.reducer";
import feedsListingReducer from "./feeds-listing/feeds-listing.reducer";
import feedsRetrievalReducer from "./feeds-retrieval/feeds-retrieval.reducer";
import feedsReducer from "./feeds/feeds.reducer";
import growersReducer from "./growers/growers.reducer";
import helpersReducer from "./helpers/helpers.reducer";
import othersDeliveryReducer from "./others-delivery/others-delivery.reducer";
import suppliersReducer from "./suppliers/suppliers.reducer";
import userReducer from "./user/user.reducer";
import vehiclesReducer from "./vehicles/vehicles.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  customers: customersReducer,
  dob: dobReducer,
  dobExpenses: dobExpenseReducer,
  drivers: driversReducer,
  expenseTypes: expenseTypesReducer,
  expenses: expensesReducer,
  farms: farmsReducer,
  feeds: feedsReducer,
  feedsListings: feedsListingReducer,
  feedsRetrievals: feedsRetrievalReducer,
  growers: growersReducer,
  helpers: helpersReducer,
  othersDelivery: othersDeliveryReducer,
  suppliers: suppliersReducer,
  user: userReducer,
  vehicles: vehiclesReducer,
});

export default persistReducer(persistConfig, rootReducer);
