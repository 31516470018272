import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import DriversActionsTypes from "./drivers.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addDriverFailure,
  addDriverSuccess,
  deleteDriverFailure,
  deleteDriverSuccess,
  retrieveDriversFailure,
  retrieveDriversSuccess,
  updateDriverFailure,
  updateDriverSuccess,
} from "./drivers.actions";

function* addDriver({
  payload: { code, counter, contactNumber, homeAddress, name },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("drivers").doc(id);

    yield reference.set({
      code,
      counter,
      contactNumber,
      homeAddress,
      id,
      name,
    });

    yield retrieveDrivers();
    yield put(addDriverSuccess());
  } catch (error) {
    yield put(addDriverFailure(error.message));
  }
}

function* deleteDriver({ payload: { id } }) {
  try {
    yield firestore.collection("drivers").doc(id).delete();

    yield retrieveDrivers();
    yield put(deleteDriverSuccess());
  } catch (error) {
    yield put(deleteDriverFailure(error.message));
  }
}

function* retrieveDrivers() {
  try {
    const reference = yield firestore.collection("drivers");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveDriversSuccess([]));
    } else {
      let drivers = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        drivers.push(data);
      });

      yield put(retrieveDriversSuccess(drivers));
    }
  } catch (error) {
    yield put(retrieveDriversFailure(error.message));
  }
}

function* updateDriver({
  payload: { code, counter, contactNumber, homeAddress, id, name },
}) {
  try {
    const reference = yield firestore.collection("drivers").doc(id);

    yield reference.update({
      code,
      counter,
      contactNumber,
      homeAddress,
      id,
      name,
    });

    yield retrieveDrivers();
    yield put(updateDriverSuccess());
  } catch (error) {
    yield put(updateDriverFailure(error.message));
  }
}

function* onAddDriverStart() {
  yield takeLatest(DriversActionsTypes.ADD_DRIVER_START, addDriver);
}

function* onDeleteDriverStart() {
  yield takeLatest(DriversActionsTypes.DELETE_DRIVER_START, deleteDriver);
}

function* onRetrieveDriversStart() {
  yield takeLatest(DriversActionsTypes.RETRIEVE_DRIVERS_START, retrieveDrivers);
}

function* onUpdateDriverStart() {
  yield takeLatest(DriversActionsTypes.UPDATE_DRIVER_START, updateDriver);
}

export function* driversSaga() {
  yield all([
    call(onAddDriverStart),
    call(onDeleteDriverStart),
    call(onRetrieveDriversStart),
    call(onUpdateDriverStart),
  ]);
}
