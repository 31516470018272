import { createSelector } from "reselect";

const selectFarms = (state) => state.farms;

export const selectIsActionLoading = createSelector(
  [selectFarms],
  (farms) => farms.actionLoading
);

export const selectAllFarms = createSelector(
  [selectFarms],
  (farms) => farms.farms
);

export const selectIsLoading = createSelector(
  [selectFarms],
  (farms) => farms.loading
);

export const selectIsSuccessful = createSelector(
  [selectFarms],
  (farms) => farms.isSuccessful
);

export const selectError = createSelector(
  [selectFarms],
  (farms) => farms.error
);
