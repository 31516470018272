import FeedsActionsTypes from "./feeds.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  feeds: [],
};

const feedsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FeedsActionsTypes.RETRIEVE_FEEDS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FeedsActionsTypes.ADD_FEED_START:
    case FeedsActionsTypes.DELETE_FEED_START:
    case FeedsActionsTypes.UPDATE_FEED_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case FeedsActionsTypes.RETRIEVE_FEEDS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        feeds: action.payload,
      };
    case FeedsActionsTypes.ADD_FEED_SUCCESS:
    case FeedsActionsTypes.DELETE_FEED_SUCCESS:
    case FeedsActionsTypes.UPDATE_FEED_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case FeedsActionsTypes.ADD_FEED_RESTART:
    case FeedsActionsTypes.DELETE_FEED_RESTART:
    case FeedsActionsTypes.UPDATE_FEED_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case FeedsActionsTypes.ADD_FEED_FAILURE:
    case FeedsActionsTypes.DELETE_FEED_FAILURE:
    case FeedsActionsTypes.RETRIEVE_FEEDS_FAILURE:
    case FeedsActionsTypes.UPDATE_FEED_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default feedsReducer;
