import ExpenseTypesActionsTypes from "./expense-types.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  expenseTypes: [],
};

const expenseTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExpenseTypesActionsTypes.RETRIEVE_EXPENSE_TYPES_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_START:
    case ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_START:
    case ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case ExpenseTypesActionsTypes.RETRIEVE_EXPENSE_TYPES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        expenseTypes: action.payload,
      };
    case ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_SUCCESS:
    case ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_SUCCESS:
    case ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_RESTART:
    case ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_RESTART:
    case ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_FAILURE:
    case ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_FAILURE:
    case ExpenseTypesActionsTypes.RETRIEVE_EXPENSE_TYPES_FAILURE:
    case ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default expenseTypesReducer;
