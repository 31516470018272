import HelpersActionsTypes from "./helpers.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  helpers: [],
};

const helpersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HelpersActionsTypes.RETRIEVE_HELPERS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case HelpersActionsTypes.ADD_HELPER_START:
    case HelpersActionsTypes.DELETE_HELPER_START:
    case HelpersActionsTypes.UPDATE_HELPER_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case HelpersActionsTypes.RETRIEVE_HELPERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        helpers: action.payload,
      };
    case HelpersActionsTypes.ADD_HELPER_SUCCESS:
    case HelpersActionsTypes.DELETE_HELPER_SUCCESS:
    case HelpersActionsTypes.UPDATE_HELPER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case HelpersActionsTypes.ADD_HELPER_RESTART:
    case HelpersActionsTypes.DELETE_HELPER_RESTART:
    case HelpersActionsTypes.UPDATE_HELPER_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case HelpersActionsTypes.ADD_HELPER_FAILURE:
    case HelpersActionsTypes.DELETE_HELPER_FAILURE:
    case HelpersActionsTypes.RETRIEVE_HELPERS_FAILURE:
    case HelpersActionsTypes.UPDATE_HELPER_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default helpersReducer;
