import React, { useState } from "react";
import {
  AddButton,
  ContentContainer,
  LayoutContainer,
  MainTitle,
} from "./OtherDelivery.styles";
import Modal from "./components/Modal";
import Table from "./components/Table";
import HelperModal from "./components/HelperModal";

const OtherDelivery = () => {
  const [data, setData] = useState();
  const [helperData, setHelperData] = useState();
  const [helpersArray, setHelpersArray] = useState([]);
  const [isHelperModalVisible, setIsHelperModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHelperEdit, setIsHelperEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  return (
    <LayoutContainer>
      <ContentContainer>
        <MainTitle level={2}>Manage Other Delivery Listing</MainTitle>
        <AddButton
          htmlType="button"
          onClick={() => setIsModalVisible(true)}
          type="primary"
        >
          Add Other Delivery
        </AddButton>
        <Modal
          data={data}
          helperEdit={setIsHelperEdit}
          helperModalVisible={isHelperModalVisible}
          helpersArray={helpersArray}
          isEdit={isEdit}
          setData={setData}
          setHelperData={setHelperData}
          setHelperModalVisible={setIsHelperModalVisible}
          setHelpersArray={setHelpersArray}
          setIsHelperEdit={setIsHelperEdit}
          setIsEdit={setIsEdit}
          setVisible={setIsModalVisible}
          visible={isModalVisible}
        />
        <HelperModal
          data={helperData}
          helpersArray={helpersArray}
          isEdit={isHelperEdit}
          setHelpersArray={setHelpersArray}
          setIsEdit={setIsHelperEdit}
          setVisible={setIsHelperModalVisible}
          visible={isHelperModalVisible}
        />
        <Table
          setData={setData}
          setIsEdit={setIsEdit}
          setVisible={setIsModalVisible}
        />
      </ContentContainer>
    </LayoutContainer>
  );
};

export default OtherDelivery;
