import DriversActionsTypes from "./drivers.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  drivers: [],
};

const driversReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DriversActionsTypes.RETRIEVE_DRIVERS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DriversActionsTypes.ADD_DRIVER_START:
    case DriversActionsTypes.DELETE_DRIVER_START:
    case DriversActionsTypes.UPDATE_DRIVER_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case DriversActionsTypes.RETRIEVE_DRIVERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        drivers: action.payload,
      };
    case DriversActionsTypes.ADD_DRIVER_SUCCESS:
    case DriversActionsTypes.DELETE_DRIVER_SUCCESS:
    case DriversActionsTypes.UPDATE_DRIVER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case DriversActionsTypes.ADD_DRIVER_RESTART:
    case DriversActionsTypes.DELETE_DRIVER_RESTART:
    case DriversActionsTypes.UPDATE_DRIVER_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case DriversActionsTypes.ADD_DRIVER_FAILURE:
    case DriversActionsTypes.DELETE_DRIVER_FAILURE:
    case DriversActionsTypes.RETRIEVE_DRIVERS_FAILURE:
    case DriversActionsTypes.UPDATE_DRIVER_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default driversReducer;
