import { createSelector } from "reselect";

const selectGrowers = (state) => state.growers;

export const selectIsActionLoading = createSelector(
  [selectGrowers],
  (growers) => growers.actionLoading
);

export const selectAllGrowers = createSelector(
  [selectGrowers],
  (growers) => growers.growers
);

export const selectIsLoading = createSelector(
  [selectGrowers],
  (growers) => growers.loading
);

export const selectIsSuccessful = createSelector(
  [selectGrowers],
  (growers) => growers.isSuccessful
);

export const selectError = createSelector(
  [selectGrowers],
  (growers) => growers.error
);
