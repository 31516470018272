import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectError,
  selectIsLoading,
  selectIsUpdateEmailSuccessful,
} from "../../../../redux/user/user.selectors";
import {
  updateEmailRestart,
  updateEmailStart,
} from "../../../../redux/user/user.actions";
import UserProfileModal from "./user-profile-modal.component";

const mapStateToProps = createStructuredSelector({
  error: selectError,
  isLoading: selectIsLoading,
  isUpdateEmailSuccessful: selectIsUpdateEmailSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  updateEmailRestart: () => dispatch(updateEmailRestart()),
  updateEmailStart: (userCredentials) =>
    dispatch(updateEmailStart(userCredentials)),
});

const UserProfileModalContainer = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(UserProfileModal);

export default UserProfileModalContainer;
