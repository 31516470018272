import FeedsListingActionsTypes from "./feeds-listing.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  feedsListings: [],
  isSuccessful: false,
  loading: false,
};

const feedsListingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FeedsListingActionsTypes.RETRIEVE_FEEDS_LISTINGS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FeedsListingActionsTypes.ADD_FEEDS_LISTING_START:
    case FeedsListingActionsTypes.DELETE_FEEDS_LISTING_START:
    case FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case FeedsListingActionsTypes.RETRIEVE_FEEDS_LISTINGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        feedsListings: action.payload,
      };
    case FeedsListingActionsTypes.ADD_FEEDS_LISTING_SUCCESS:
    case FeedsListingActionsTypes.DELETE_FEEDS_LISTING_SUCCESS:
    case FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case FeedsListingActionsTypes.ADD_FEEDS_LISTING_RESTART:
    case FeedsListingActionsTypes.DELETE_FEEDS_LISTING_RESTART:
    case FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case FeedsListingActionsTypes.ADD_FEEDS_LISTING_FAILURE:
    case FeedsListingActionsTypes.DELETE_FEEDS_LISTING_FAILURE:
    case FeedsListingActionsTypes.RETRIEVE_FEEDS_LISTING_EXPENSES_FAILURE:
    case FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default feedsListingReducer;
