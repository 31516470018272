const OthersDeliveryActionsTypes = {
  ADD_OTHERS_DELIVERY_START: "ADD_OTHERS_DELIVERY_START",
  ADD_OTHERS_DELIVERY_SUCCESS: "ADD_OTHERS_DELIVERY_SUCCESS",
  ADD_OTHERS_DELIVERY_FAILURE: "ADD_OTHERS_DELIVERY_FAILURE",
  ADD_OTHERS_DELIVERY_RESTART: "ADD_OTHERS_DELIVERY_RESTART",
  DELETE_OTHERS_DELIVERY_START: "DELETE_OTHERS_DELIVERY_START",
  DELETE_OTHERS_DELIVERY_SUCCESS: "DELETE_OTHERS_DELIVERY_SUCCESS",
  DELETE_OTHERS_DELIVERY_FAILURE: "DELETE_OTHERS_DELIVERY_FAILURE",
  DELETE_OTHERS_DELIVERY_RESTART: "DELETE_OTHERS_DELIVERY_RESTART",
  UPDATE_OTHERS_DELIVERY_START: "UPDATE_OTHERS_DELIVERY_START",
  UPDATE_OTHERS_DELIVERY_SUCCESS: "UPDATE_OTHERS_DELIVERY_SUCCESS",
  UPDATE_OTHERS_DELIVERY_FAILURE: "UPDATE_OTHERS_DELIVERY_FAILURE",
  UPDATE_OTHERS_DELIVERY_RESTART: "UPDATE_OTHERS_DELIVERY_RESTART",
  RETRIEVE_OTHERS_DELIVERY_START: "RETRIEVE_OTHERS_DELIVERY_START",
  RETRIEVE_OTHERS_DELIVERY_SUCCESS: "RETRIEVE_OTHERS_DELIVERY_SUCCESS",
  RETRIEVE_OTHERS_DELIVERY_FAILURE: "RETRIEVE_OTHERS_DELIVERY_FAILURE",
};

export default OthersDeliveryActionsTypes;
