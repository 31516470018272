import { all, call } from "redux-saga/effects";
import { customersSaga } from "./customers/customers.sagas";
import { dobExpenseSaga } from "./dob-expense/dob-expense.sagas";
import { dobSaga } from "./dob/dob.sagas";
import { driversSaga } from "./drivers/drivers.sagas";
import { expenseTypesSaga } from "./expense-types/expense-types.sagas";
import { expensesSaga } from "./expenses/expenses.sagas";
import { farmsSaga } from "./farms/farms.sagas";
import { feedsListingSaga } from "./feeds-listing/feeds-listing.sagas";
import { feedsRetrievalsSaga } from "./feeds-retrieval/feeds-retrieval.sagas";
import { feedsSaga } from "./feeds/feeds.sagas";
import { growersSaga } from "./growers/growers.sagas";
import { helpersSaga } from "./helpers/helpers.sagas";
import { othersDeliverySaga } from "./others-delivery/others-delivery.sagas";
import { suppliersSaga } from "./suppliers/suppliers.sagas";
import { userSaga } from "./user/user.sagas";
import { vehiclesSaga } from "./vehicles/vehicles.sagas";

export default function* rootSaga() {
  yield all([
    call(customersSaga),
    call(dobSaga),
    call(dobExpenseSaga),
    call(driversSaga),
    call(expenseTypesSaga),
    call(expensesSaga),
    call(farmsSaga),
    call(feedsSaga),
    call(feedsListingSaga),
    call(feedsRetrievalsSaga),
    call(growersSaga),
    call(helpersSaga),
    call(othersDeliverySaga),
    call(suppliersSaga),
    call(userSaga),
    call(vehiclesSaga),
  ]);
}
