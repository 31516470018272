const DobsActionsTypes = {
  ADD_DOB_START: "ADD_DOB_START",
  ADD_DOB_SUCCESS: "ADD_DOB_SUCCESS",
  ADD_DOB_FAILURE: "ADD_DOB_FAILURE",
  ADD_DOB_RESTART: "ADD_DOB_RESTART",
  DELETE_DOB_START: "DELETE_DOB_START",
  DELETE_DOB_SUCCESS: "DELETE_DOB_SUCCESS",
  DELETE_DOB_FAILURE: "DELETE_DOB_FAILURE",
  DELETE_DOB_RESTART: "DELETE_DOB_RESTART",
  UPDATE_DOB_START: "UPDATE_DOB_START",
  UPDATE_DOB_SUCCESS: "UPDATE_DOB_SUCCESS",
  UPDATE_DOB_FAILURE: "UPDATE_DOB_FAILURE",
  UPDATE_DOB_RESTART: "UPDATE_DOB_RESTART",
  RETRIEVE_DOBS_START: "RETRIEVE_DOBS_START",
  RETRIEVE_DOBS_SUCCESS: "RETRIEVE_DOBS_SUCCESS",
  RETRIEVE_DOBS_FAILURE: "RETRIEVE_DOBS_FAILURE",
};

export default DobsActionsTypes;
