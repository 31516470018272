import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import SuppliersActionsTypes from "./suppliers.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addSupplierFailure,
  addSupplierSuccess,
  deleteSupplierFailure,
  deleteSupplierSuccess,
  retrieveSuppliersFailure,
  retrieveSuppliersSuccess,
  updateSupplierFailure,
  updateSupplierSuccess,
} from "./suppliers.actions";

function* addSupplier({
  payload: { businessAddress, code, contactNumber, counter, name },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("suppliers").doc(id);

    yield reference.set({
      businessAddress,
      code,
      contactNumber,
      counter,
      id,
      name,
    });

    yield retrieveSuppliers();
    yield put(addSupplierSuccess());
  } catch (error) {
    yield put(addSupplierFailure(error.message));
  }
}

function* deleteSupplier({ payload: { id } }) {
  try {
    yield firestore.collection("suppliers").doc(id).delete();

    yield retrieveSuppliers();
    yield put(deleteSupplierSuccess());
  } catch (error) {
    yield put(deleteSupplierFailure(error.message));
  }
}

function* retrieveSuppliers() {
  try {
    const reference = yield firestore.collection("suppliers");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveSuppliersSuccess([]));
    } else {
      let suppliers = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        suppliers.push(data);
      });

      yield put(retrieveSuppliersSuccess(suppliers));
    }
  } catch (error) {
    yield put(retrieveSuppliersFailure(error.message));
  }
}

function* updateSupplier({
  payload: { businessAddress, code, contactNumber, counter, id, name },
}) {
  try {
    const reference = yield firestore.collection("suppliers").doc(id);

    yield reference.update({
      businessAddress,
      code,
      contactNumber,
      counter,
      id,
      name,
    });

    yield retrieveSuppliers();
    yield put(updateSupplierSuccess());
  } catch (error) {
    yield put(updateSupplierFailure(error.message));
  }
}

function* onAddSupplierStart() {
  yield takeLatest(SuppliersActionsTypes.ADD_SUPPLIER_START, addSupplier);
}

function* onDeleteSupplierStart() {
  yield takeLatest(SuppliersActionsTypes.DELETE_SUPPLIER_START, deleteSupplier);
}

function* onRetrieveSuppliersStart() {
  yield takeLatest(
    SuppliersActionsTypes.RETRIEVE_SUPPLIERS_START,
    retrieveSuppliers
  );
}

function* onUpdateSupplierStart() {
  yield takeLatest(SuppliersActionsTypes.UPDATE_SUPPLIER_START, updateSupplier);
}

export function* suppliersSaga() {
  yield all([
    call(onAddSupplierStart),
    call(onDeleteSupplierStart),
    call(onRetrieveSuppliersStart),
    call(onUpdateSupplierStart),
  ]);
}
