import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import FeedsListingActionsTypes from "./feeds-listing.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addFeedsListingFailure,
  addFeedsListingSuccess,
  deleteFeedsListingFailure,
  deleteFeedsListingSuccess,
  retrieveFeedsListingsFailure,
  retrieveFeedsListingsSuccess,
  updateFeedsListingFailure,
  updateFeedsListingSuccess,
} from "./feeds-listing.actions";

function* addFeedsListing({
  payload: {
    actualBags,
    documentNo,
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    remarks,
    vehicleName,
  },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("feedsListings").doc(id);

    yield reference.set({
      actualBags,
      date: new Date().toLocaleDateString(),
      documentNo,
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,

      remarks,
      vehicleName,
    });

    yield retrieveFeedsListings();
    yield put(addFeedsListingSuccess());
  } catch (error) {
    yield put(addFeedsListingFailure(error.message));
  }
}

function* deleteFeedsListing({ payload: { id } }) {
  try {
    yield firestore.collection("feedsListings").doc(id).delete();

    yield retrieveFeedsListings();
    yield put(deleteFeedsListingSuccess());
  } catch (error) {
    yield put(deleteFeedsListingFailure(error.message));
  }
}

function* retrieveFeedsListings() {
  try {
    const reference = yield firestore.collection("feedsListings");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveFeedsListingsSuccess([]));
    } else {
      let dobExpenses = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        dobExpenses.push(data);
      });

      yield put(retrieveFeedsListingsSuccess(dobExpenses));
    }
  } catch (error) {
    yield put(retrieveFeedsListingsFailure(error.message));
  }
}

function* updateFeedsListing({
  payload: {
    actualBags,
    documentNo,
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    id,
    remarks,
    vehicleName,
  },
}) {
  try {
    const reference = yield firestore.collection("feedsListings").doc(id);

    yield reference.update({
      actualBags,
      documentNo,
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,

      remarks,
      vehicleName,
    });

    yield retrieveFeedsListings();
    yield put(updateFeedsListingSuccess());
  } catch (error) {
    yield put(updateFeedsListingFailure(error.message));
  }
}

function* onAddFeedsListingStart() {
  yield takeLatest(
    FeedsListingActionsTypes.ADD_FEEDS_LISTING_START,
    addFeedsListing
  );
}

function* onDeleteFeedsListingStart() {
  yield takeLatest(
    FeedsListingActionsTypes.DELETE_FEEDS_LISTING_START,
    deleteFeedsListing
  );
}

function* onRetrieveFeedsListingsStart() {
  yield takeLatest(
    FeedsListingActionsTypes.RETRIEVE_FEEDS_LISTINGS_START,
    retrieveFeedsListings
  );
}

function* onUpdateFeedsListingStart() {
  yield takeLatest(
    FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_START,
    updateFeedsListing
  );
}

export function* feedsListingSaga() {
  yield all([
    call(onAddFeedsListingStart),
    call(onDeleteFeedsListingStart),
    call(onRetrieveFeedsListingsStart),
    call(onUpdateFeedsListingStart),
  ]);
}
