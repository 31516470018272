import DriversActionsTypes from "./farms.types";

export const addFarmStart = (data) => ({
  type: DriversActionsTypes.ADD_FARM_START,
  payload: data,
});

export const addFarmSuccess = () => ({
  type: DriversActionsTypes.ADD_FARM_SUCCESS,
});

export const addFarmFailure = (error) => ({
  type: DriversActionsTypes.ADD_FARM_FAILURE,
  payload: error,
});

export const addFarmRestart = () => ({
  type: DriversActionsTypes.ADD_FARM_RESTART,
});

export const deleteFarmStart = (data) => ({
  type: DriversActionsTypes.DELETE_FARM_START,
  payload: data,
});

export const deleteFarmSuccess = () => ({
  type: DriversActionsTypes.DELETE_FARM_SUCCESS,
});

export const deleteFarmFailure = (error) => ({
  type: DriversActionsTypes.DELETE_FARM_FAILURE,
  payload: error,
});

export const deleteFarmRestart = () => ({
  type: DriversActionsTypes.DELETE_FARM_RESTART,
});

export const retrieveFarmsStart = () => ({
  type: DriversActionsTypes.RETRIEVE_FARMS_START,
});

export const retrieveFarmsSuccess = (data) => ({
  type: DriversActionsTypes.RETRIEVE_FARMS_SUCCESS,
  payload: data,
});

export const retrieveFarmsFailure = (error) => ({
  type: DriversActionsTypes.RETRIEVE_FARMS_FAILURE,
  payload: error,
});

export const updateFarmStart = (data) => ({
  type: DriversActionsTypes.UPDATE_FARM_START,
  payload: data,
});

export const updateFarmSuccess = () => ({
  type: DriversActionsTypes.UPDATE_FARM_SUCCESS,
});

export const updateFarmFailure = (error) => ({
  type: DriversActionsTypes.UPDATE_FARM_FAILURE,
  payload: error,
});

export const updateFarmRestart = () => ({
  type: DriversActionsTypes.UPDATE_FARM_RESTART,
});
