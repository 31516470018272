import ExpensesActionsTypes from "./expenses.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  expenses: [],
};

const expensesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExpensesActionsTypes.RETRIEVE_EXPENSES_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ExpensesActionsTypes.ADD_EXPENSE_START:
    case ExpensesActionsTypes.DELETE_EXPENSE_START:
    case ExpensesActionsTypes.UPDATE_EXPENSE_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case ExpensesActionsTypes.RETRIEVE_EXPENSES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        expenses: action.payload,
      };
    case ExpensesActionsTypes.ADD_EXPENSE_SUCCESS:
    case ExpensesActionsTypes.DELETE_EXPENSE_SUCCESS:
    case ExpensesActionsTypes.UPDATE_EXPENSE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case ExpensesActionsTypes.ADD_EXPENSE_RESTART:
    case ExpensesActionsTypes.DELETE_EXPENSE_RESTART:
    case ExpensesActionsTypes.UPDATE_EXPENSE_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case ExpensesActionsTypes.ADD_EXPENSE_FAILURE:
    case ExpensesActionsTypes.DELETE_EXPENSE_FAILURE:
    case ExpensesActionsTypes.RETRIEVE_EXPENSES_FAILURE:
    case ExpensesActionsTypes.UPDATE_EXPENSE_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default expensesReducer;
