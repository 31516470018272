import FeedsListingActionsTypes from "./feeds-listing.types";

export const addFeedsListingStart = (data) => ({
  type: FeedsListingActionsTypes.ADD_FEEDS_LISTING_START,
  payload: data,
});

export const addFeedsListingSuccess = () => ({
  type: FeedsListingActionsTypes.ADD_FEEDS_LISTING_SUCCESS,
});

export const addFeedsListingFailure = (error) => ({
  type: FeedsListingActionsTypes.ADD_FEEDS_LISTING_FAILURE,
  payload: error,
});

export const addFeedsListingRestart = () => ({
  type: FeedsListingActionsTypes.ADD_FEEDS_LISTING_RESTART,
});

export const deleteFeedsListingStart = (data) => ({
  type: FeedsListingActionsTypes.DELETE_FEEDS_LISTING_START,
  payload: data,
});

export const deleteFeedsListingSuccess = () => ({
  type: FeedsListingActionsTypes.DELETE_FEEDS_LISTING_SUCCESS,
});

export const deleteFeedsListingFailure = (error) => ({
  type: FeedsListingActionsTypes.DELETE_FEEDS_LISTING_FAILURE,
  payload: error,
});

export const deleteFeedsListingRestart = () => ({
  type: FeedsListingActionsTypes.DELETE_FEEDS_LISTING_RESTART,
});

export const retrieveFeedsListingsStart = () => ({
  type: FeedsListingActionsTypes.RETRIEVE_FEEDS_LISTINGS_START,
});

export const retrieveFeedsListingsSuccess = (data) => ({
  type: FeedsListingActionsTypes.RETRIEVE_FEEDS_LISTINGS_SUCCESS,
  payload: data,
});

export const retrieveFeedsListingsFailure = (error) => ({
  type: FeedsListingActionsTypes.RETRIEVE_FEEDS_LISTINGS_FAILURE,
  payload: error,
});

export const updateFeedsListingStart = (data) => ({
  type: FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_START,
  payload: data,
});

export const updateFeedsListingSuccess = () => ({
  type: FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_SUCCESS,
});

export const updateFeedsListingFailure = (error) => ({
  type: FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_FAILURE,
  payload: error,
});

export const updateFeedsListingRestart = () => ({
  type: FeedsListingActionsTypes.UPDATE_FEEDS_LISTING_RESTART,
});
