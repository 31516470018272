import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import { selectAllHelpers } from "../../../../../../redux/helpers/helpers.selectors";

const { Option } = Select;

const HelperModal = ({
  data,
  helpers,
  helpersArray,
  isEdit,
  visible,
  setHelpersArray,
  setIsEdit,
  setVisible,
}) => {
  const onFinish = (values) => {
    const allowances = parseFloat(values.allowances);
    const incentives = parseFloat(values.incentives);
    const others = parseFloat(values.others);
    const salary = parseFloat(values.salary);
    const total = allowances + incentives + others + salary;
    const { helperName } = values;
    const id = helpers.filter((helper) => helper.name === helperName)[0].id;

    if (!isEdit) {
      setHelpersArray((helpersArray) => [
        ...helpersArray,
        { allowances, helperName, id, incentives, others, salary, total },
      ]);
      setVisible(false);
      openNotificationSuccess("You have successfully added a new helper data!");
    } else {
      let newHelperData = helpersArray.filter(
        (helper) => helper.id !== data.id
      );

      let newHelpersArray;

      if (newHelperData.length > 0) {
        newHelpersArray = [
          ...newHelperData,
          { allowances, helperName, id, incentives, others, salary, total },
        ];
      } else {
        newHelpersArray = [];
      }

      openNotificationSuccess(
        "You have successfully updated the selected helper data!"
      );
      setHelpersArray(newHelpersArray);
      setVisible(false);
      setIsEdit(false);
    }
  };

  const openNotificationSuccess = (description) => {
    notification.success({
      message: `Success!`,
      duration: 5,
      description,
      placement: "topRight",
    });
  };

  const handleCancel = () => {
    setIsEdit(false);
    setVisible(false);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      title="Manage Helpers Details"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <Form.Item
          label="Name of Helper"
          name="helperName"
          rules={[
            {
              required: true,
              message: "Please select helper!",
            },
          ]}
          initialValue={isEdit ? data.helperName : ""}
        >
          <Select>
            {helpers &&
              helpers.map((helper) => {
                const exists = helpersArray.some(
                  (data) => data.id === helper.id
                );

                if (isEdit && exists) {
                  return <Option value={helper.name}>{helper.name}</Option>;
                }

                if (!exists) {
                  return <Option value={helper.name}>{helper.name}</Option>;
                }

                return null;
              })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Salary"
          name="salary"
          rules={[
            {
              required: true,
              message: "Please input salary!",
            },
          ]}
          initialValue={isEdit ? data.salary : ""}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Incentives"
          name="incentives"
          rules={[
            {
              required: true,
              message: "Please input incentives!",
            },
          ]}
          initialValue={isEdit ? data.incentives : ""}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Allowances"
          name="allowances"
          rules={[
            {
              required: true,
              message: "Please input allowances!",
            },
          ]}
          initialValue={isEdit ? data.allowances : ""}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Others"
          name="others"
          rules={[
            {
              required: true,
              message: "Please input others!",
            },
          ]}
          initialValue={isEdit ? data.others : ""}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Update Helper" : "Add Helper"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  helpers: selectAllHelpers,
});

export default connect(mapStateToProps)(HelperModal);
