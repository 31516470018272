import FeedsActionsTypes from "./feeds.types";

export const addFeedStart = (data) => ({
  type: FeedsActionsTypes.ADD_FEED_START,
  payload: data,
});

export const addFeedSuccess = () => ({
  type: FeedsActionsTypes.ADD_FEED_SUCCESS,
});

export const addFeedFailure = (error) => ({
  type: FeedsActionsTypes.ADD_FEED_FAILURE,
  payload: error,
});

export const addFeedRestart = () => ({
  type: FeedsActionsTypes.ADD_FEED_RESTART,
});

export const deleteFeedStart = (data) => ({
  type: FeedsActionsTypes.DELETE_FEED_START,
  payload: data,
});

export const deleteFeedSuccess = () => ({
  type: FeedsActionsTypes.DELETE_FEED_SUCCESS,
});

export const deleteFeedFailure = (error) => ({
  type: FeedsActionsTypes.DELETE_FEED_FAILURE,
  payload: error,
});

export const deleteFeedRestart = () => ({
  type: FeedsActionsTypes.DELETE_FEED_RESTART,
});

export const retrieveFeedsStart = () => ({
  type: FeedsActionsTypes.RETRIEVE_FEEDS_START,
});

export const retrieveFeedsSuccess = (data) => ({
  type: FeedsActionsTypes.RETRIEVE_FEEDS_SUCCESS,
  payload: data,
});

export const retrieveFeedsFailure = (error) => ({
  type: FeedsActionsTypes.RETRIEVE_FEEDS_FAILURE,
  payload: error,
});

export const updateFeedStart = (data) => ({
  type: FeedsActionsTypes.UPDATE_FEED_START,
  payload: data,
});

export const updateFeedSuccess = () => ({
  type: FeedsActionsTypes.UPDATE_FEED_SUCCESS,
});

export const updateFeedFailure = (error) => ({
  type: FeedsActionsTypes.UPDATE_FEED_FAILURE,
  payload: error,
});

export const updateFeedRestart = () => ({
  type: FeedsActionsTypes.UPDATE_FEED_RESTART,
});
