import styled from "styled-components";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DatePickerContainer = styled(DatePicker)`
  width: 100%;
  margin-right: 0.5rem;
`;

export const FormItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FormItem = styled(Form.Item)`
  width: 100%;
  margin-right: 0.5rem;
`;
