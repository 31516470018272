import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import HelpersActionsTypes from "./helpers.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addHelperFailure,
  addHelperSuccess,
  deleteHelperFailure,
  deleteHelperSuccess,
  retrieveHelpersFailure,
  retrieveHelpersSuccess,
  updateHelperFailure,
  updateHelperSuccess,
} from "./helpers.actions";

function* addHelper({
  payload: { code, counter, homeAddress, name, contactNumber },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("helpers").doc(id);

    yield reference.set({
      code,
      counter,
      homeAddress,
      id,
      name,
      contactNumber,
    });

    yield retrieveHelpers();
    yield put(addHelperSuccess());
  } catch (error) {
    yield put(addHelperFailure(error.message));
  }
}

function* deleteHelper({ payload: { id } }) {
  try {
    yield firestore.collection("helpers").doc(id).delete();

    yield retrieveHelpers();
    yield put(deleteHelperSuccess());
  } catch (error) {
    yield put(deleteHelperFailure(error.message));
  }
}

function* retrieveHelpers() {
  try {
    const reference = yield firestore.collection("helpers");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveHelpersSuccess([]));
    } else {
      let helpers = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        helpers.push(data);
      });

      yield put(retrieveHelpersSuccess(helpers));
    }
  } catch (error) {
    yield put(retrieveHelpersFailure(error.message));
  }
}

function* updateHelper({
  payload: { code, counter, homeAddress, id, name, contactNumber },
}) {
  try {
    const reference = yield firestore.collection("helpers").doc(id);

    yield reference.update({
      code,
      counter,
      homeAddress,
      id,
      name,
      contactNumber,
    });

    yield retrieveHelpers();
    yield put(updateHelperSuccess());
  } catch (error) {
    yield put(updateHelperFailure(error.message));
  }
}

function* onAddHelperStart() {
  yield takeLatest(HelpersActionsTypes.ADD_HELPER_START, addHelper);
}

function* onDeleteHelperStart() {
  yield takeLatest(HelpersActionsTypes.DELETE_HELPER_START, deleteHelper);
}

function* onRetrieveHelperStart() {
  yield takeLatest(HelpersActionsTypes.RETRIEVE_HELPERS_START, retrieveHelpers);
}

function* onUpdateHelperStart() {
  yield takeLatest(HelpersActionsTypes.UPDATE_HELPER_START, updateHelper);
}

export function* helpersSaga() {
  yield all([
    call(onAddHelperStart),
    call(onDeleteHelperStart),
    call(onRetrieveHelperStart),
    call(onUpdateHelperStart),
  ]);
}
