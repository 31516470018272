import { createSelector } from "reselect";

const selectOthersDelivery = (state) => state.othersDelivery;

export const selectIsActionLoading = createSelector(
  [selectOthersDelivery],
  (othersDelivery) => othersDelivery.actionLoading
);

export const selectAllOthersDelivery = createSelector(
  [selectOthersDelivery],
  (othersDelivery) => othersDelivery.othersDelivery
);

export const selectIsLoading = createSelector(
  [selectOthersDelivery],
  (othersDelivery) => othersDelivery.loading
);

export const selectIsSuccessful = createSelector(
  [selectOthersDelivery],
  (othersDelivery) => othersDelivery.isSuccessful
);

export const selectError = createSelector(
  [selectOthersDelivery],
  (othersDelivery) => othersDelivery.error
);
