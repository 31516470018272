const FeedsActionsTypes = {
  ADD_FEED_START: "ADD_FEED_START",
  ADD_FEED_SUCCESS: "ADD_FEED_SUCCESS",
  ADD_FEED_FAILURE: "ADD_FEED_FAILURE",
  ADD_FEED_RESTART: "ADD_FEED_RESTART",
  DELETE_FEED_START: "DELETE_FEED_START",
  DELETE_FEED_SUCCESS: "DELETE_FEED_SUCCESS",
  DELETE_FEED_FAILURE: "DELETE_FEED_FAILURE",
  DELETE_FEED_RESTART: "DELETE_FEED_RESTART",
  UPDATE_FEED_START: "UPDATE_FEED_START",
  UPDATE_FEED_SUCCESS: "UPDATE_FEED_SUCCESS",
  UPDATE_FEED_FAILURE: "UPDATE_FEED_FAILURE",
  UPDATE_FEED_RESTART: "UPDATE_FEED_RESTART",
  RETRIEVE_FEEDS_START: "RETRIEVE_FEEDS_START",
  RETRIEVE_FEEDS_SUCCESS: "RETRIEVE_FEEDS_SUCCESS",
  RETRIEVE_FEEDS_FAILURE: "RETRIEVE_FEEDS_FAILURE",
};

export default FeedsActionsTypes;
