import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import {
  addFarmRestart,
  addFarmStart,
  updateFarmRestart,
  updateFarmStart,
} from "../../../../../../redux/farms/farms.actions";
import {
  selectAllFarms,
  selectIsActionLoading,
  selectIsSuccessful,
} from "../../../../../../redux/farms/farms.selectors";
import TextArea from "antd/es/input/TextArea";

const FarmModal = ({
  addFarmRestart,
  addFarmStart,
  farms,
  data,
  error,
  isActionLoading,
  isEdit,
  isSuccessful,
  updateFarmRestart,
  updateFarmStart,
  visible,
  setIsEdit,
  setVisible,
}) => {
  const [codeCount, setCodeCount] = useState("001");

  useEffect(() => {
    if (farms.length > 0) {
      const farmsCount = farms.length + 1;

      setCodeCount("00" + farmsCount.toString());
    }
  }, [farms]);

  const onFinish = (values) => {
    const { code, location, name } = values;
    const id = document.querySelector(".id").value;

    if (!isEdit) {
      addFarmStart({ code, location, name });
    } else {
      updateFarmStart({ code, id, location, name });
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccessful) {
      setVisible(false);
      setIsEdit(false);
      addFarmRestart();
      return updateFarmRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        setIsEdit(false);
      }
    }
  }, [
    addFarmRestart,
    error,
    isSuccessful,
    updateFarmRestart,
    setIsEdit,
    setVisible,
  ]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Manage Farm Details"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <Form.Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input code!",
            },
          ]}
          initialValue={isEdit ? data.code : codeCount}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Name of Farm"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name of farm!",
            },
          ]}
          initialValue={isEdit ? data.name : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Location | Area"
          name="location"
          rules={[
            {
              required: true,
              message: "Please input location | area!",
            },
          ]}
          initialValue={isEdit ? data.location : ""}
        >
          <TextArea rows={3} cols={4} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isActionLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Update Farm" : "Add Farm"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  farms: selectAllFarms,
  isActionLoading: selectIsActionLoading,
  isSuccessful: selectIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  addFarmRestart: () => dispatch(addFarmRestart()),
  addFarmStart: (data) => dispatch(addFarmStart(data)),
  updateFarmRestart: () => dispatch(updateFarmRestart()),
  updateFarmStart: (data) => dispatch(updateFarmStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmModal);
