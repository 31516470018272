import { createSelector } from "reselect";

const selectExpenseTypes = (state) => state.expenseTypes;

export const selectIsActionLoading = createSelector(
  [selectExpenseTypes],
  (expenseTypes) => expenseTypes.actionLoading
);

export const selectAllExpenseTypes = createSelector(
  [selectExpenseTypes],
  (expenseTypes) => expenseTypes.expenseTypes
);

export const selectIsLoading = createSelector(
  [selectExpenseTypes],
  (expenseTypes) => expenseTypes.loading
);

export const selectIsSuccessful = createSelector(
  [selectExpenseTypes],
  (expenseTypes) => expenseTypes.isSuccessful
);

export const selectError = createSelector(
  [selectExpenseTypes],
  (expenseTypes) => expenseTypes.error
);
