import React, { useState } from "react";
import {
  AddButton,
  CandidateTitle,
  ContentContainer,
  LayoutContainer,
} from "./Vehicles.styles";
import Modal from "./components/Modal";
import Table from "./components/Table";

const Vehicles = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState();

  return (
    <LayoutContainer>
      <ContentContainer>
        <CandidateTitle level={2}>Manage Vehicles</CandidateTitle>
        <AddButton
          htmlType="button"
          onClick={() => setIsModalVisible(true)}
          type="primary"
        >
          Add Vehicle
        </AddButton>
        <Modal
          data={data}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setVisible={setIsModalVisible}
          visible={isModalVisible}
        />
        <Table
          setData={setData}
          setIsEdit={setIsEdit}
          setVisible={setIsModalVisible}
        />
      </ContentContainer>
    </LayoutContainer>
  );
};

export default Vehicles;
