import { createSelector } from "reselect";

const selectExpenses = (state) => state.expenses;

export const selectIsActionLoading = createSelector(
  [selectExpenses],
  (expenses) => expenses.actionLoading
);

export const selectAllExpenses = createSelector(
  [selectExpenses],
  (expenses) => expenses.expenses
);

export const selectIsLoading = createSelector(
  [selectExpenses],
  (expenses) => expenses.loading
);

export const selectIsSuccessful = createSelector(
  [selectExpenses],
  (expenses) => expenses.isSuccessful
);

export const selectError = createSelector(
  [selectExpenses],
  (expenses) => expenses.error
);
