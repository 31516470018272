const DriversActionsTypes = {
  ADD_DRIVER_START: "ADD_DRIVER_START",
  ADD_DRIVER_SUCCESS: "ADD_DRIVER_SUCCESS",
  ADD_DRIVER_FAILURE: "ADD_DRIVER_FAILURE",
  ADD_DRIVER_RESTART: "ADD_DRIVER_RESTART",
  DELETE_DRIVER_START: "DELETE_DRIVER_START",
  DELETE_DRIVER_SUCCESS: "DELETE_DRIVER_SUCCESS",
  DELETE_DRIVER_FAILURE: "DELETE_DRIVER_FAILURE",
  DELETE_DRIVER_RESTART: "DELETE_DRIVER_RESTART",
  UPDATE_DRIVER_START: "UPDATE_DRIVER_START",
  UPDATE_DRIVER_SUCCESS: "UPDATE_DRIVER_SUCCESS",
  UPDATE_DRIVER_FAILURE: "UPDATE_DRIVER_FAILURE",
  UPDATE_DRIVER_RESTART: "UPDATE_DRIVER_RESTART",
  RETRIEVE_DRIVERS_START: "RETRIEVE_DRIVERS_START",
  RETRIEVE_DRIVERS_SUCCESS: "RETRIEVE_DRIVERS_SUCCESS",
  RETRIEVE_DRIVERS_FAILURE: "RETRIEVE_DRIVERS_FAILURE",
};

export default DriversActionsTypes;
