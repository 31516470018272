import ExpensesActionsTypes from "./expenses.types";

export const addExpenseStart = (data) => ({
  type: ExpensesActionsTypes.ADD_EXPENSE_START,
  payload: data,
});

export const addExpenseSuccess = () => ({
  type: ExpensesActionsTypes.ADD_EXPENSE_SUCCESS,
});

export const addExpenseFailure = (error) => ({
  type: ExpensesActionsTypes.ADD_EXPENSE_FAILURE,
  payload: error,
});

export const addExpenseRestart = () => ({
  type: ExpensesActionsTypes.ADD_EXPENSE_RESTART,
});

export const deleteExpenseStart = (data) => ({
  type: ExpensesActionsTypes.DELETE_EXPENSE_START,
  payload: data,
});

export const deleteExpenseSuccess = () => ({
  type: ExpensesActionsTypes.DELETE_EXPENSE_SUCCESS,
});

export const deleteExpenseFailure = (error) => ({
  type: ExpensesActionsTypes.DELETE_EXPENSE_FAILURE,
  payload: error,
});

export const deleteExpenseRestart = () => ({
  type: ExpensesActionsTypes.DELETE_EXPENSE_RESTART,
});

export const retrieveExpensesStart = () => ({
  type: ExpensesActionsTypes.RETRIEVE_EXPENSES_START,
});

export const retrieveExpensesSuccess = (data) => ({
  type: ExpensesActionsTypes.RETRIEVE_EXPENSES_SUCCESS,
  payload: data,
});

export const retrieveExpensesFailure = (error) => ({
  type: ExpensesActionsTypes.RETRIEVE_EXPENSES_FAILURE,
  payload: error,
});

export const updateExpenseStart = (data) => ({
  type: ExpensesActionsTypes.UPDATE_EXPENSE_START,
  payload: data,
});

export const updateExpenseSuccess = () => ({
  type: ExpensesActionsTypes.UPDATE_EXPENSE_SUCCESS,
});

export const updateExpenseFailure = (error) => ({
  type: ExpensesActionsTypes.UPDATE_EXPENSE_FAILURE,
  payload: error,
});

export const updateExpenseRestart = () => ({
  type: ExpensesActionsTypes.UPDATE_EXPENSE_RESTART,
});
