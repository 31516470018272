import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import Select from "antd/es/select";
import {
  addExpenseRestart,
  addExpenseStart,
  updateExpenseRestart,
  updateExpenseStart,
} from "../../../../../../redux/expenses/expenses.actions";
import {
  selectAllExpenses,
  selectIsActionLoading,
  selectIsSuccessful,
} from "../../../../../../redux/expenses/expenses.selectors";
import { selectAllExpenseTypes } from "../../../../../../redux/expense-types/expense-types.selectors";

const { Option } = Select;

const CustomerModal = ({
  addExpenseRestart,
  addExpenseStart,
  expenses,
  expenseTypes,
  data,
  error,
  isActionLoading,
  isEdit,
  isSuccessful,
  updateExpenseRestart,
  updateExpenseStart,
  visible,
  setIsEdit,
  setVisible,
}) => {
  const [codeCount, setCodeCount] = useState("001");

  useEffect(() => {
    if (expenses.length > 0) {
      const customersCount = expenses.length + 1;

      setCodeCount("00" + customersCount.toString());
    }
  }, [expenses]);

  const onFinish = (values) => {
    const { code, counter, name, type } = values;
    const id = document.querySelector(".id").value;

    if (!isEdit) {
      addExpenseStart({ code, counter, name, type });
    } else {
      updateExpenseStart({ code, counter, id, name, type });
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccessful) {
      setVisible(false);
      setIsEdit(false);
      addExpenseRestart();
      return updateExpenseRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        setIsEdit(false);
      }
    }
  }, [
    addExpenseRestart,
    error,
    isSuccessful,
    updateExpenseRestart,
    setIsEdit,
    setVisible,
  ]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Manage Expense Details"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <Form.Item
          label="Counter"
          name="counter"
          rules={[
            {
              required: true,
              message: "Please input counter!",
            },
          ]}
          initialValue={isEdit ? data.counter : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Account Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input code!",
            },
          ]}
          initialValue={isEdit ? data.code : codeCount}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Account Title Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input account title name!",
            },
          ]}
          initialValue={isEdit ? data.name : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Expense Type"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select expense type!",
            },
          ]}
          initialValue={isEdit ? data.type : ""}
        >
          <Select>
            {expenseTypes &&
              expenseTypes.map((expenseType) => (
                <Option value={expenseType.name}>{expenseType.name}</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            loading={isActionLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Update Expense" : "Add Expense"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  expenses: selectAllExpenses,
  expenseTypes: selectAllExpenseTypes,
  isActionLoading: selectIsActionLoading,
  isSuccessful: selectIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  addExpenseRestart: () => dispatch(addExpenseRestart()),
  addExpenseStart: (data) => dispatch(addExpenseStart(data)),
  updateExpenseRestart: () => dispatch(updateExpenseRestart()),
  updateExpenseStart: (data) => dispatch(updateExpenseStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModal);
