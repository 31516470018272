import HelpersActionsTypes from "./helpers.types";

export const addHelperStart = (data) => ({
  type: HelpersActionsTypes.ADD_HELPER_START,
  payload: data,
});

export const addHelperSuccess = () => ({
  type: HelpersActionsTypes.ADD_HELPER_SUCCESS,
});

export const addHelperFailure = (error) => ({
  type: HelpersActionsTypes.ADD_HELPER_FAILURE,
  payload: error,
});

export const addHelperRestart = () => ({
  type: HelpersActionsTypes.ADD_HELPER_RESTART,
});

export const deleteHelperStart = (data) => ({
  type: HelpersActionsTypes.DELETE_HELPER_START,
  payload: data,
});

export const deleteHelperSuccess = () => ({
  type: HelpersActionsTypes.DELETE_HELPER_SUCCESS,
});

export const deleteHelperFailure = (error) => ({
  type: HelpersActionsTypes.DELETE_HELPER_FAILURE,
  payload: error,
});

export const deleteHelperRestart = () => ({
  type: HelpersActionsTypes.DELETE_HELPER_RESTART,
});

export const retrieveHelpersStart = () => ({
  type: HelpersActionsTypes.RETRIEVE_HELPERS_START,
});

export const retrieveHelpersSuccess = (data) => ({
  type: HelpersActionsTypes.RETRIEVE_HELPERS_SUCCESS,
  payload: data,
});

export const retrieveHelpersFailure = (error) => ({
  type: HelpersActionsTypes.RETRIEVE_HELPERS_FAILURE,
  payload: error,
});

export const updateHelperStart = (data) => ({
  type: HelpersActionsTypes.UPDATE_HELPER_START,
  payload: data,
});

export const updateHelperSuccess = () => ({
  type: HelpersActionsTypes.UPDATE_HELPER_SUCCESS,
});

export const updateHelperFailure = (error) => ({
  type: HelpersActionsTypes.UPDATE_HELPER_FAILURE,
  payload: error,
});

export const updateHelperRestart = () => ({
  type: HelpersActionsTypes.UPDATE_HELPER_RESTART,
});
