import SuppliersActionsTypes from "./suppliers.types";

export const addSupplierStart = (data) => ({
  type: SuppliersActionsTypes.ADD_SUPPLIER_START,
  payload: data,
});

export const addSupplierSuccess = () => ({
  type: SuppliersActionsTypes.ADD_SUPPLIER_SUCCESS,
});

export const addSupplierFailure = (error) => ({
  type: SuppliersActionsTypes.ADD_SUPPLIER_FAILURE,
  payload: error,
});

export const addSupplierRestart = () => ({
  type: SuppliersActionsTypes.ADD_SUPPLIER_RESTART,
});

export const deleteSupplierStart = (data) => ({
  type: SuppliersActionsTypes.DELETE_SUPPLIER_START,
  payload: data,
});

export const deleteSupplierSuccess = () => ({
  type: SuppliersActionsTypes.DELETE_SUPPLIER_SUCCESS,
});

export const deleteSupplierFailure = (error) => ({
  type: SuppliersActionsTypes.DELETE_SUPPLIER_FAILURE,
  payload: error,
});

export const deleteSupplierRestart = () => ({
  type: SuppliersActionsTypes.DELETE_SUPPLIER_RESTART,
});

export const retrieveSuppliersStart = () => ({
  type: SuppliersActionsTypes.RETRIEVE_SUPPLIERS_START,
});

export const retrieveSuppliersSuccess = (data) => ({
  type: SuppliersActionsTypes.RETRIEVE_SUPPLIERS_SUCCESS,
  payload: data,
});

export const retrieveSuppliersFailure = (error) => ({
  type: SuppliersActionsTypes.RETRIEVE_SUPPLIERS_FAILURE,
  payload: error,
});

export const updateSupplierStart = (data) => ({
  type: SuppliersActionsTypes.UPDATE_SUPPLIER_START,
  payload: data,
});

export const updateSupplierSuccess = () => ({
  type: SuppliersActionsTypes.UPDATE_SUPPLIER_SUCCESS,
});

export const updateSupplierFailure = (error) => ({
  type: SuppliersActionsTypes.UPDATE_SUPPLIER_FAILURE,
  payload: error,
});

export const updateSupplierRestart = () => ({
  type: SuppliersActionsTypes.UPDATE_SUPPLIER_RESTART,
});
