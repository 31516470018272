import { createSelector } from "reselect";

const selectDobExpense = (state) => state.dobExpenses;

export const selectIsActionLoading = createSelector(
  [selectDobExpense],
  (dobExpenses) => dobExpenses.actionLoading
);

export const selectAllDobExpenses = createSelector(
  [selectDobExpense],
  (dobExpenses) => dobExpenses.dobExpenses
);

export const selectIsLoading = createSelector(
  [selectDobExpense],
  (dobExpenses) => dobExpenses.loading
);

export const selectIsSuccessful = createSelector(
  [selectDobExpense],
  (dobExpenses) => dobExpenses.isSuccessful
);

export const selectError = createSelector(
  [selectDobExpense],
  (dobExpenses) => dobExpenses.error
);
