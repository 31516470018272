import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectError,
  selectIsLoading,
  selectIsUpdatePasswordSuccessful,
} from "../../../../redux/user/user.selectors";
import {
  updatePasswordStart,
  updatePasswordRestart,
} from "../../../../redux/user/user.actions";
import PasswordModal from "./password-modal.component";

const mapStateToProps = createStructuredSelector({
  error: selectError,
  isLoading: selectIsLoading,
  isUpdatePasswordSuccessful: selectIsUpdatePasswordSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  updatePasswordRestart: () => dispatch(updatePasswordRestart()),
  updatePasswordStart: (userCredentials) =>
    dispatch(updatePasswordStart(userCredentials)),
});

const PasswordModalContainer = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PasswordModal);

export default PasswordModalContainer;
