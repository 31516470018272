import React, { useEffect } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";

const PasswordModal = ({
  error,
  isLoading,
  isUpdatePasswordSuccessful,
  user,
  visible,
  setVisible,
  updatePasswordRestart,
  updatePasswordStart,
}) => {
  const onFinish = (values) => {
    const { confirmPassword, newPassword, oldPassword } = values;
    const { email, id, password } = user;

    if (oldPassword !== password) {
      return openNotificationError("Your old password is incorrect.");
    }

    if (confirmPassword !== newPassword) {
      return openNotificationError(
        "Your new password doesn't match the confirm password."
      );
    }

    if (newPassword.length < 6) {
      return openNotificationError(
        "Password should have at least 6 characters."
      );
    }

    updatePasswordStart({
      email,
      id,
      newPassword,
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const openNotificationSuccess = () => {
    notification.success({
      message: `Success!`,
      duration: 5,
      description: "Successfully updated your password.",
      placement: "topRight",
    });
  };

  const openNotificationError = (message) => {
    notification.error({
      message: `Uh-oh!`,
      duration: 5,
      description: message,
      placement: "topRight",
    });
  };

  useEffect(() => {
    if (isUpdatePasswordSuccessful) {
      setVisible(false);
      openNotificationSuccess();
      return updatePasswordRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        return openNotificationError(error);
      }
    }
  }, [error, isUpdatePasswordSuccessful, updatePasswordRestart, setVisible]);

  return (
    <Modal
      destroyOnClose
      title="Change Password"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form {...layout} onFinish={onFinish}>
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: "Please input your old password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 17 }}>
          <Button
            loading={isLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
