const SuppliersActionsTypes = {
  ADD_SUPPLIER_START: "ADD_SUPPLIER_START",
  ADD_SUPPLIER_SUCCESS: "ADD_SUPPLIER_SUCCESS",
  ADD_SUPPLIER_FAILURE: "ADD_SUPPLIER_FAILURE",
  ADD_SUPPLIER_RESTART: "ADD_SUPPLIER_RESTART",
  DELETE_SUPPLIER_START: "DELETE_SUPPLIER_START",
  DELETE_SUPPLIER_SUCCESS: "DELETE_SUPPLIER_SUCCESS",
  DELETE_SUPPLIER_FAILURE: "DELETE_SUPPLIER_FAILURE",
  DELETE_SUPPLIER_RESTART: "DELETE_SUPPLIER_RESTART",
  UPDATE_SUPPLIER_START: "UPDATE_SUPPLIER_START",
  UPDATE_SUPPLIER_SUCCESS: "UPDATE_SUPPLIER_SUCCESS",
  UPDATE_SUPPLIER_FAILURE: "UPDATE_SUPPLIER_FAILURE",
  UPDATE_SUPPLIER_RESTART: "UPDATE_SUPPLIER_RESTART",
  RETRIEVE_SUPPLIERS_START: "RETRIEVE_SUPPLIERS_START",
  RETRIEVE_SUPPLIERS_SUCCESS: "RETRIEVE_SUPPLIERS_SUCCESS",
  RETRIEVE_SUPPLIERS_FAILURE: "RETRIEVE_SUPPLIERS_FAILURE",
};

export default SuppliersActionsTypes;
