const FeedsRetrievalsActionsTypes = {
  ADD_FEEDS_RETRIEVAL_START: "ADD_FEEDS_RETRIEVAL_START",
  ADD_FEEDS_RETRIEVAL_SUCCESS: "ADD_FEEDS_RETRIEVAL_SUCCESS",
  ADD_FEEDS_RETRIEVAL_FAILURE: "ADD_FEEDS_RETRIEVAL_FAILURE",
  ADD_FEEDS_RETRIEVAL_RESTART: "ADD_FEEDS_RETRIEVAL_RESTART",
  DELETE_FEEDS_RETRIEVAL_START: "DELETE_FEEDS_RETRIEVAL_START",
  DELETE_FEEDS_RETRIEVAL_SUCCESS: "DELETE_FEEDS_RETRIEVAL_SUCCESS",
  DELETE_FEEDS_RETRIEVAL_FAILURE: "DELETE_FEEDS_RETRIEVAL_FAILURE",
  DELETE_FEEDS_RETRIEVAL_RESTART: "DELETE_FEEDS_RETRIEVAL_RESTART",
  UPDATE_FEEDS_RETRIEVAL_START: "UPDATE_FEEDS_RETRIEVAL_START",
  UPDATE_FEEDS_RETRIEVAL_SUCCESS: "UPDATE_FEEDS_RETRIEVAL_SUCCESS",
  UPDATE_FEEDS_RETRIEVAL_FAILURE: "UPDATE_FEEDS_RETRIEVAL_FAILURE",
  UPDATE_FEEDS_RETRIEVAL_RESTART: "UPDATE_FEEDS_RETRIEVAL_RESTART",
  RETRIEVE_FEEDS_RETRIEVALS_START: "RETRIEVE_FEEDS_RETRIEVALS_START",
  RETRIEVE_FEEDS_RETRIEVALS_SUCCESS: "RETRIEVE_FEEDS_RETRIEVALS_SUCCESS",
  RETRIEVE_FEEDS_RETRIEVALS_FAILURE: "RETRIEVE_FEEDS_RETRIEVALS_FAILURE",
};

export default FeedsRetrievalsActionsTypes;
