import ExpenseTypesActionsTypes from "./expense-types.types";

export const addExpenseTypeStart = (data) => ({
  type: ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_START,
  payload: data,
});

export const addExpenseTypeSuccess = () => ({
  type: ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_SUCCESS,
});

export const addExpenseTypeFailure = (error) => ({
  type: ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_FAILURE,
  payload: error,
});

export const addExpenseTypeRestart = () => ({
  type: ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_RESTART,
});

export const deleteExpenseTypeStart = (data) => ({
  type: ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_START,
  payload: data,
});

export const deleteExpenseTypeSuccess = () => ({
  type: ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_SUCCESS,
});

export const deleteExpenseTypeFailure = (error) => ({
  type: ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_FAILURE,
  payload: error,
});

export const deleteExpenseTypeRestart = () => ({
  type: ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_RESTART,
});

export const retrieveExpenseTypesStart = () => ({
  type: ExpenseTypesActionsTypes.RETRIEVE_EXPENSE_TYPES_START,
});

export const retrieveExpenseTypesSuccess = (data) => ({
  type: ExpenseTypesActionsTypes.RETRIEVE_EXPENSE_TYPES_SUCCESS,
  payload: data,
});

export const retrieveExpenseTypesFailure = (error) => ({
  type: ExpenseTypesActionsTypes.RETRIEVE_EXPENSE_TYPES_FAILURE,
  payload: error,
});

export const updateExpenseTypeStart = (data) => ({
  type: ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_START,
  payload: data,
});

export const updateExpenseTypeSuccess = () => ({
  type: ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_SUCCESS,
});

export const updateExpenseTypeFailure = (error) => ({
  type: ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_FAILURE,
  payload: error,
});

export const updateExpenseTypeRestart = () => ({
  type: ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_RESTART,
});
