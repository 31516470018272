import { takeLatest, put, all, call } from "redux-saga/effects";
import UserActionTypes from "./user.types";
import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
  updatePasswordFailure,
  updatePasswordSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  updateEmailFailure,
  updateEmailSuccess,
} from "./user.actions";
import firebase, {
  auth,
  createUserProfileDocument,
  firestore,
  getCurrentUser,
} from "../../firebase/firebase.utils";

function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(
      createUserProfileDocument,
      userAuth,
      additionalData
    );
    const userSnapshot = yield userRef.get();

    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
  } catch (error) {
    yield put(signInFailure(error.message));
  }
}

function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);

    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error.message));
  }
}

function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();

    if (!userAuth) return;

    yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    yield put(signInFailure(error.message));
  }
}

function* signOut() {
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error.message));
  }
}

function* signUp({
  payload: { address, contactNo, displayName, email, password, position },
}) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);

    yield put(
      signUpSuccess({
        user,
        additionalData: {
          address,
          contactNo,
          displayName,
          password,
          position,
        },
      })
    );
  } catch (error) {
    yield put(signUpFailure(error.message));
  }
}

function* updateEmail({ payload: { email, id, password } }) {
  try {
    const currentUser = firebase.auth().currentUser;
    yield currentUser.updateEmail(email);

    const docRef = yield firestore.collection("users").doc(id);
    yield docRef.update({
      email,
    });

    const { user } = yield auth.signInWithEmailAndPassword(email, password);

    yield getSnapshotFromUserAuth(user);
    yield put(updateEmailSuccess());
  } catch (error) {
    yield put(updateEmailFailure(error.message));
  }
}

function* updatePassword({ payload: { email, id, newPassword } }) {
  try {
    const currentUser = yield firebase.auth().currentUser;
    yield currentUser.updatePassword(newPassword);

    const docRef = yield firestore.collection("users").doc(id);
    yield docRef.update({
      password: newPassword,
    });

    const { user } = yield auth.signInWithEmailAndPassword(email, newPassword);

    yield getSnapshotFromUserAuth(user);
    yield put(updatePasswordSuccess());
  } catch (error) {
    yield put(updatePasswordFailure(error.message));
  }
}

function* updateProfile({
  payload: {
    address,
    currentEmail,
    contactNo,
    displayName,
    email,
    id,
    password,
    position,
  },
}) {
  try {
    if (currentEmail !== email) {
      const user = firebase.auth().currentUser;

      yield user.updateEmail(email);
    }

    const docRef = yield firestore.collection("users").doc(id);
    yield docRef.update({
      address,
      contactNo,
      displayName,
      password,
      position,
    });

    const { user } = yield auth.signInWithEmailAndPassword(email, password);

    yield getSnapshotFromUserAuth(user);
    yield put(updateProfileSuccess());
  } catch (error) {
    yield put(updateProfileFailure(error.message));
  }
}

function* signInAfterSignUp({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

function* onUpdateEmailStart() {
  yield takeLatest(UserActionTypes.UPDATE_EMAIL_START, updateEmail);
}

function* onUpdatePasswordStart() {
  yield takeLatest(UserActionTypes.UPDATE_PASSWORD_START, updatePassword);
}

function* onUpdateProfileStart() {
  yield takeLatest(UserActionTypes.UPDATE_PROFILE_START, updateProfile);
}

export function* userSaga() {
  yield all([
    call(onCheckUserSession),
    call(onEmailSignInStart),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onUpdateEmailStart),
    call(onUpdatePasswordStart),
    call(onUpdateProfileStart),
  ]);
}
