import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import DobsActionsTypes from "./dob.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addDobFailure,
  addDobSuccess,
  deleteDobFailure,
  deleteDobSuccess,
  retrieveDobsFailure,
  retrieveDobsSuccess,
  updateDobFailure,
  updateDobSuccess,
} from "./dob.actions";

function* addDob({
  payload: {
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    minimumCapacity,
    remarks,
    rmsdNo,
    vehicleName,
  },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("dob").doc(id);

    yield reference.set({
      date: new Date().toLocaleDateString(),
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      minimumCapacity,
      remarks,
      rmsdNo,
      vehicleName,
    });

    yield retrieveDobs();
    yield put(addDobSuccess());
  } catch (error) {
    yield put(addDobFailure(error.message));
  }
}

function* deleteDob({ payload: { id } }) {
  try {
    yield firestore.collection("dob").doc(id).delete();

    yield retrieveDobs();
    yield put(deleteDobSuccess());
  } catch (error) {
    yield put(deleteDobFailure(error.message));
  }
}

function* retrieveDobs() {
  try {
    const reference = yield firestore.collection("dob");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveDobsSuccess([]));
    } else {
      let dob = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        dob.push(data);
      });

      yield put(retrieveDobsSuccess(dob));
    }
  } catch (error) {
    yield put(retrieveDobsFailure(error.message));
  }
}

function* updateDob({
  payload: {
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    id,
    minimumCapacity,
    remarks,
    rmsdNo,
    vehicleName,
  },
}) {
  try {
    const reference = yield firestore.collection("dob").doc(id);

    yield reference.update({
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      minimumCapacity,
      remarks,
      rmsdNo,
      vehicleName,
    });

    yield retrieveDobs();
    yield put(updateDobSuccess());
  } catch (error) {
    yield put(updateDobFailure(error.message));
  }
}

function* onAddDobStart() {
  yield takeLatest(DobsActionsTypes.ADD_DOB_START, addDob);
}

function* onDeleteDobStart() {
  yield takeLatest(DobsActionsTypes.DELETE_DOB_START, deleteDob);
}

function* onRetrieveDobsStart() {
  yield takeLatest(DobsActionsTypes.RETRIEVE_DOBS_START, retrieveDobs);
}

function* onUpdateDobStart() {
  yield takeLatest(DobsActionsTypes.UPDATE_DOB_START, updateDob);
}

export function* dobSaga() {
  yield all([
    call(onAddDobStart),
    call(onDeleteDobStart),
    call(onRetrieveDobsStart),
    call(onUpdateDobStart),
  ]);
}
