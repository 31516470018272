import GrowersActionsTypes from "./growers.types";

export const addGrowerStart = (data) => ({
  type: GrowersActionsTypes.ADD_GROWER_START,
  payload: data,
});

export const addGrowerSuccess = () => ({
  type: GrowersActionsTypes.ADD_GROWER_SUCCESS,
});

export const addGrowerFailure = (error) => ({
  type: GrowersActionsTypes.ADD_GROWER_FAILURE,
  payload: error,
});

export const addGrowerRestart = () => ({
  type: GrowersActionsTypes.ADD_GROWER_RESTART,
});

export const deleteGrowerStart = (data) => ({
  type: GrowersActionsTypes.DELETE_GROWER_START,
  payload: data,
});

export const deleteGrowerSuccess = () => ({
  type: GrowersActionsTypes.DELETE_GROWER_SUCCESS,
});

export const deleteGrowerFailure = (error) => ({
  type: GrowersActionsTypes.DELETE_GROWER_FAILURE,
  payload: error,
});

export const deleteGrowerRestart = () => ({
  type: GrowersActionsTypes.DELETE_GROWER_RESTART,
});

export const retrieveGrowersStart = () => ({
  type: GrowersActionsTypes.RETRIEVE_GROWERS_START,
});

export const retrieveGrowersSuccess = (data) => ({
  type: GrowersActionsTypes.RETRIEVE_GROWERS_SUCCESS,
  payload: data,
});

export const retrieveGrowersFailure = (error) => ({
  type: GrowersActionsTypes.RETRIEVE_GROWERS_FAILURE,
  payload: error,
});

export const updateGrowerStart = (data) => ({
  type: GrowersActionsTypes.UPDATE_GROWER_START,
  payload: data,
});

export const updateGrowerSuccess = () => ({
  type: GrowersActionsTypes.UPDATE_GROWER_SUCCESS,
});

export const updateGrowerFailure = (error) => ({
  type: GrowersActionsTypes.UPDATE_GROWER_FAILURE,
  payload: error,
});

export const updateGrowerRestart = () => ({
  type: GrowersActionsTypes.UPDATE_GROWER_RESTART,
});
