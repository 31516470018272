import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { signOutStart } from "../../redux/user/user.actions";
import Dropdown from "antd/es/dropdown";
import Layout from "antd/es/layout";
import Menu from "antd/es/menu";
import notification from "antd/es/notification";
import Title from "antd/es/typography/Title";
import {
  CopyOutlined,
  // DashboardOutlined,
  DatabaseOutlined,
  DiffOutlined,
  FormOutlined,
  SettingOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import {
  HeaderContainer,
  LayoutContainer,
  UserButtonContainer,
} from "./Admin.styles";
import PasswordModal from "./components/password-modal";
import UserProfileModal from "./components/user-profile-modal";
import {
  billingCategories,
  maintenanceCategories,
  quickLinksCategories,
  reportsCategories,
  transactionsCategories,
} from "./categories";
import Customers from "./components/maintenance/customers/Customers";
import Dob from "./components/quick-links/dob/Dob";
import Drivers from "./components/maintenance/drivers/Drivers";
import ExpenseTypes from "./components/maintenance/expense-types/ExpenseTypes";
import Expenses from "./components/maintenance/expenses/Expenses";
import Farms from "./components/maintenance/farms/Farms";
import Helpers from "./components/maintenance/helpers/Helpers";
import Suppliers from "./components/maintenance/suppliers/Suppliers";
import Vehicles from "./components/maintenance/vehicles/Vehicles";
import DOBExpense from "./components/quick-links/dob-expense/DobExpense";
import Feeds from "./components/quick-links/feeds/Feeds";
import FeedsRetrieval from "./components/quick-links/feeds-retrieval/FeedsRetrieval";
import Growers from "./components/quick-links/growers/Growers";
import OtherDelivery from "./components/quick-links/other-delivery/OtherDelivery";

const { Sider } = Layout;

const AdminPage = ({ history, signOutStart, user }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState("Customers");
  const [isManagePasswordVisible, setIsManagePasswordVisible] = useState(false);
  const [isUserProfileVisible, setIsUserProfileVisible] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsUserProfileVisible(true)}>
        Profile Settings
      </Menu.Item>
      <Menu.Item onClick={() => setIsManagePasswordVisible(true)}>
        Change Password
      </Menu.Item>
      <Menu.Item onClick={() => signOutStart()}>Sign Out</Menu.Item>
    </Menu>
  );

  const openNotificationSuccess = () => {
    notification.success({
      message: `Success!`,
      duration: 5,
      description: "You have successfully logged out!",
      placement: "topRight",
    });
  };

  const onCollapse = (collapsed) => setCollapsed(collapsed);

  useEffect(() => {
    if (user === null) {
      openNotificationSuccess();
      return history.push("/");
    }
  }, [history, user]);

  return (
    <LayoutContainer>
      <HeaderContainer className="header">
        <Title
          style={{
            color: "white",
          }}
          level={3}
        >
          M and D Trucking
        </Title>
        <Dropdown overlay={menu}>
          <UserButtonContainer
            style={{
              backgroundColor: "#001628",
              color: "white",
              width: 150,
            }}
            icon={<SettingOutlined />}
          >
            {user ? user.displayName : "Admin"}
          </UserButtonContainer>
        </Dropdown>
      </HeaderContainer>
      <Layout>
        <Sider
          className="site-layout-background"
          collapsed={collapsed}
          collapsible
          onCollapse={onCollapse}
          width={300}
        >
          <Menu
            defaultSelectedKeys={["Customers"]}
            defaultOpenKeys={["Customers"]}
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
          >
            {/* <Menu.Item
              key="dashboard"
              icon={<DashboardOutlined />}
              onClick={() => setSelectedKey("dashboard")}
            >
              Dashboard
            </Menu.Item> */}
            <Menu.SubMenu
              key="maintenance"
              icon={<DatabaseOutlined />}
              title="Maintenance"
            >
              {maintenanceCategories.map((maintenance) => (
                <Menu.Item
                  key={maintenance}
                  onClick={() => setSelectedKey(maintenance)}
                >
                  {maintenance}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.SubMenu key="billing" icon={<CopyOutlined />} title="Billing">
              {billingCategories.map((billing) => (
                <Menu.Item
                  key={billing}
                  onClick={() => setSelectedKey(billing)}
                >
                  {billing}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.SubMenu
              key="quickLinks"
              icon={<FormOutlined />}
              title="Quick Links"
            >
              {quickLinksCategories.map((quickLinks) => (
                <Menu.Item
                  key={quickLinks}
                  onClick={() => setSelectedKey(quickLinks)}
                >
                  {quickLinks}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.SubMenu
              key="transactions"
              icon={<SnippetsOutlined />}
              title="Transactions"
            >
              {transactionsCategories.map((transactions) => (
                <Menu.Item
                  key={transactions}
                  onClick={() => setSelectedKey(transactions)}
                >
                  {transactions}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.SubMenu key="reports" icon={<DiffOutlined />} title="Reports">
              {reportsCategories.map((reports) => (
                <Menu.Item
                  key={reports}
                  onClick={() => setSelectedKey(reports)}
                >
                  {reports}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          </Menu>
        </Sider>
        {selectedKey === "Customers" ? <Customers /> : null}
        {selectedKey === "Drivers" ? <Drivers /> : null}
        {selectedKey === "Expense Types" ? <ExpenseTypes /> : null}
        {selectedKey === "Expenses" ? <Expenses /> : null}
        {selectedKey === "Farms" ? <Farms /> : null}
        {selectedKey === "Feeds" ? <Feeds /> : null}
        {selectedKey === "Feeds Retrieval" ? <FeedsRetrieval /> : null}
        {selectedKey === "Growers" ? <Growers /> : null}
        {selectedKey === "Helpers" ? <Helpers /> : null}
        {selectedKey === "Manage Other Delivery" ? <OtherDelivery /> : null}
        {selectedKey === "Suppliers" ? <Suppliers /> : null}
        {selectedKey === "Vehicles | Trucking" ? <Vehicles /> : null}
        {selectedKey === "DOB" ? <Dob /> : null}
        {selectedKey === "DOB with Expense" ? <DOBExpense /> : null}
        <UserProfileModal
          setVisible={setIsUserProfileVisible}
          user={user}
          visible={isUserProfileVisible}
        />
        <PasswordModal
          setVisible={setIsManagePasswordVisible}
          user={user}
          visible={isManagePasswordVisible}
        />
      </Layout>
    </LayoutContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
