import { createSelector } from "reselect";

const selectFeedsListing = (state) => state.feedsListings;

export const selectIsActionLoading = createSelector(
  [selectFeedsListing],
  (feedsListings) => feedsListings.actionLoading
);

export const selectAllFeedsListings = createSelector(
  [selectFeedsListing],
  (feedsListings) => feedsListings.feedsListings
);

export const selectIsLoading = createSelector(
  [selectFeedsListing],
  (feedsListings) => feedsListings.loading
);

export const selectIsSuccessful = createSelector(
  [selectFeedsListing],
  (feedsListings) => feedsListings.isSuccessful
);

export const selectError = createSelector(
  [selectFeedsListing],
  (feedsListings) => feedsListings.error
);
