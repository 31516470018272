import { createSelector } from "reselect";

const selectFeedsRetrievals = (state) => state.feedsRetrievals;

export const selectIsActionLoading = createSelector(
  [selectFeedsRetrievals],
  (feedsRetrievals) => feedsRetrievals.actionLoading
);

export const selectAllFeedsRetrievals = createSelector(
  [selectFeedsRetrievals],
  (feedsRetrievals) => feedsRetrievals.feedsRetrievals
);

export const selectIsLoading = createSelector(
  [selectFeedsRetrievals],
  (feedsRetrievals) => feedsRetrievals.loading
);

export const selectIsSuccessful = createSelector(
  [selectFeedsRetrievals],
  (feedsRetrievals) => feedsRetrievals.isSuccessful
);

export const selectError = createSelector(
  [selectFeedsRetrievals],
  (feedsRetrievals) => feedsRetrievals.error
);
