import DobExpensesActionsTypes from "./dob-expense.types";

export const addDobExpenseStart = (data) => ({
  type: DobExpensesActionsTypes.ADD_DOB_EXPENSE_START,
  payload: data,
});

export const addDobExpenseSuccess = () => ({
  type: DobExpensesActionsTypes.ADD_DOB_EXPENSE_SUCCESS,
});

export const addDobExpenseFailure = (error) => ({
  type: DobExpensesActionsTypes.ADD_DOB_EXPENSE_FAILURE,
  payload: error,
});

export const addDobExpenseRestart = () => ({
  type: DobExpensesActionsTypes.ADD_DOB_EXPENSE_RESTART,
});

export const deleteDobExpenseStart = (data) => ({
  type: DobExpensesActionsTypes.DELETE_DOB_EXPENSE_START,
  payload: data,
});

export const deleteDobExpenseSuccess = () => ({
  type: DobExpensesActionsTypes.DELETE_DOB_EXPENSE_SUCCESS,
});

export const deleteDobExpenseFailure = (error) => ({
  type: DobExpensesActionsTypes.DELETE_DOB_EXPENSE_FAILURE,
  payload: error,
});

export const deleteDobExpenseRestart = () => ({
  type: DobExpensesActionsTypes.DELETE_DOB_EXPENSE_RESTART,
});

export const retrieveDobExpensesStart = () => ({
  type: DobExpensesActionsTypes.RETRIEVE_DOB_EXPENSES_START,
});

export const retrieveDobExpensesSuccess = (data) => ({
  type: DobExpensesActionsTypes.RETRIEVE_DOB_EXPENSES_SUCCESS,
  payload: data,
});

export const retrieveDobExpensesFailure = (error) => ({
  type: DobExpensesActionsTypes.RETRIEVE_DOB_EXPENSES_FAILURE,
  payload: error,
});

export const updateDobExpenseStart = (data) => ({
  type: DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_START,
  payload: data,
});

export const updateDobExpenseSuccess = () => ({
  type: DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_SUCCESS,
});

export const updateDobExpenseFailure = (error) => ({
  type: DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_FAILURE,
  payload: error,
});

export const updateDobExpenseRestart = () => ({
  type: DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_RESTART,
});
