const GrowersActionsTypes = {
  ADD_GROWER_START: "ADD_GROWER_START",
  ADD_GROWER_SUCCESS: "ADD_GROWER_SUCCESS",
  ADD_GROWER_FAILURE: "ADD_GROWER_FAILURE",
  ADD_GROWER_RESTART: "ADD_GROWER_RESTART",
  DELETE_GROWER_START: "DELETE_GROWER_START",
  DELETE_GROWER_SUCCESS: "DELETE_GROWER_SUCCESS",
  DELETE_GROWER_FAILURE: "DELETE_GROWER_FAILURE",
  DELETE_GROWER_RESTART: "DELETE_GROWER_RESTART",
  UPDATE_GROWER_START: "UPDATE_GROWER_START",
  UPDATE_GROWER_SUCCESS: "UPDATE_GROWER_SUCCESS",
  UPDATE_GROWER_FAILURE: "UPDATE_GROWER_FAILURE",
  UPDATE_GROWER_RESTART: "UPDATE_GROWER_RESTART",
  RETRIEVE_GROWERS_START: "RETRIEVE_GROWERS_START",
  RETRIEVE_GROWERS_SUCCESS: "RETRIEVE_GROWERS_SUCCESS",
  RETRIEVE_GROWERS_FAILURE: "RETRIEVE_GROWERS_FAILURE",
};

export default GrowersActionsTypes;
