const UserActionTypes = {
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  UPDATE_EMAIL_START: "UPDATE_EMAIL_START",
  UPDATE_EMAIL_SUCCESS: "UPDATE_EMAIL_SUCCESS",
  UPDATE_EMAIL_FAILURE: "UPDATE_EMAIL_FAILURE",
  UPDATE_EMAIL_RESTART: "UPDATE_EMAIL_RESTART",
  UPDATE_PASSWORD_START: "UPDATE_PASSWORD_START",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  UPDATE_PASSWORD_RESTART: "UPDATE_PASSWORD_RESTART",
  UPDATE_PROFILE_START: "UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  UPDATE_PROFILE_RESTART: "UPDATE_PROFILE_RESTART",
  USER_RESTART: "USER_RESTART",
};

export default UserActionTypes;
