import FeedsRetrievalsActionsTypes from "./feeds-retrieval.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  feedsRetrievals: [],
};

const feedsRetrievalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FeedsRetrievalsActionsTypes.RETRIEVE_FEEDS_RETRIEVALS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_START:
    case FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_START:
    case FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case FeedsRetrievalsActionsTypes.RETRIEVE_FEEDS_RETRIEVALS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        feedsRetrievals: action.payload,
      };
    case FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_SUCCESS:
    case FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_SUCCESS:
    case FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_RESTART:
    case FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_RESTART:
    case FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_FAILURE:
    case FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_FAILURE:
    case FeedsRetrievalsActionsTypes.RETRIEVE_FEEDS_RETRIEVALS_FAILURE:
    case FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default feedsRetrievalReducer;
