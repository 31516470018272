import { createSelector } from "reselect";

const selectDobs = (state) => state.dob;

export const selectIsActionLoading = createSelector(
  [selectDobs],
  (dob) => dob.actionLoading
);

export const selectAllDobs = createSelector([selectDobs], (dob) => dob.dobs);

export const selectIsLoading = createSelector(
  [selectDobs],
  (dob) => dob.loading
);

export const selectIsSuccessful = createSelector(
  [selectDobs],
  (dob) => dob.isSuccessful
);

export const selectError = createSelector([selectDobs], (dob) => dob.error);
