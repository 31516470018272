const VehiclesActionsTypes = {
  ADD_VEHICLE_START: "ADD_VEHICLE_START",
  ADD_VEHICLE_SUCCESS: "ADD_VEHICLE_SUCCESS",
  ADD_VEHICLE_FAILURE: "ADD_VEHICLE_FAILURE",
  ADD_VEHICLE_RESTART: "ADD_VEHICLE_RESTART",
  DELETE_VEHICLE_START: "DELETE_VEHICLE_START",
  DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
  DELETE_VEHICLE_FAILURE: "DELETE_VEHICLE_FAILURE",
  DELETE_VEHICLE_RESTART: "DELETE_VEHICLE_RESTART",
  UPDATE_VEHICLE_START: "UPDATE_VEHICLE_START",
  UPDATE_VEHICLE_SUCCESS: "UPDATE_VEHICLE_SUCCESS",
  UPDATE_VEHICLE_FAILURE: "UPDATE_VEHICLE_FAILURE",
  UPDATE_VEHICLE_RESTART: "UPDATE_VEHICLE_RESTART",
  RETRIEVE_VEHICLES_START: "RETRIEVE_VEHICLES_START",
  RETRIEVE_VEHICLES_SUCCESS: "RETRIEVE_VEHICLES_SUCCESS",
  RETRIEVE_VEHICLES_FAILURE: "RETRIEVE_VEHICLES_FAILURE",
};

export default VehiclesActionsTypes;
