import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import {
  addCustomerRestart,
  addCustomerStart,
  updateCustomerRestart,
  updateCustomerStart,
} from "../../../../../../redux/customers/customers.actions";
import {
  selectAllCustomers,
  selectIsActionLoading,
  selectIsSuccessful,
} from "../../../../../../redux/customers/customers.selectors";
import TextArea from "antd/es/input/TextArea";

const CustomerModal = ({
  addCustomerRestart,
  addCustomerStart,
  customers,
  data,
  error,
  isActionLoading,
  isEdit,
  isSuccessful,
  updateCustomerRestart,
  updateCustomerStart,
  visible,
  setIsEdit,
  setVisible,
}) => {
  const [codeCount, setCodeCount] = useState("001");

  useEffect(() => {
    if (customers.length > 0) {
      const customersCount = customers.length + 1;

      setCodeCount("00" + customersCount.toString());
    }
  }, [customers]);

  const onFinish = (values) => {
    const { businessAddress, code, contactNumber, name } = values;
    const id = document.querySelector(".id").value;

    if (!isEdit) {
      addCustomerStart({ businessAddress, code, contactNumber, name });
    } else {
      updateCustomerStart({ businessAddress, code, contactNumber, id, name });
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccessful) {
      setVisible(false);
      setIsEdit(false);
      addCustomerRestart();
      return updateCustomerRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        setIsEdit(false);
      }
    }
  }, [
    addCustomerRestart,
    error,
    isSuccessful,
    updateCustomerRestart,
    setIsEdit,
    setVisible,
  ]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Manage Customer Details"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <Form.Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input code!",
            },
          ]}
          initialValue={isEdit ? data.code : codeCount}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Name of Customer"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name of customer!",
            },
          ]}
          initialValue={isEdit ? data.name : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Business Address"
          name="businessAddress"
          rules={[
            {
              required: true,
              message: "Please input business address!",
            },
          ]}
          initialValue={isEdit ? data.businessAddress : ""}
        >
          <TextArea rows={3} cols={4} />
        </Form.Item>
        <Form.Item
          label="Contact Number"
          name="contactNumber"
          rules={[
            {
              required: true,
              message: "Please input contact number!",
            },
          ]}
          initialValue={isEdit ? data.contactNumber : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isActionLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Update Customer" : "Add Customer"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  customers: selectAllCustomers,
  isActionLoading: selectIsActionLoading,
  isSuccessful: selectIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  addCustomerRestart: () => dispatch(addCustomerRestart()),
  addCustomerStart: (data) => dispatch(addCustomerStart(data)),
  updateCustomerRestart: () => dispatch(updateCustomerRestart()),
  updateCustomerStart: (data) => dispatch(updateCustomerStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModal);
