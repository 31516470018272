import React, { Fragment, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Spinner } from "./components";
import GlobalStyle from "./global.styles";
import AdminPage from "./pages/admin";
import { LoginPage } from "./pages/auth";

const App = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route component={LoginPage} exact path="/" />
          <Route component={AdminPage} exact path="/dashboard" />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default App;
