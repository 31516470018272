const DobExpensesActionsTypes = {
  ADD_DOB_EXPENSE_START: "ADD_DOB_EXPENSE_START",
  ADD_DOB_EXPENSE_SUCCESS: "ADD_DOB_EXPENSE_SUCCESS",
  ADD_DOB_EXPENSE_FAILURE: "ADD_DOB_EXPENSE_FAILURE",
  ADD_DOB_EXPENSE_RESTART: "ADD_DOB_EXPENSE_RESTART",
  DELETE_DOB_EXPENSE_START: "DELETE_DOB_EXPENSE_START",
  DELETE_DOB_EXPENSE_SUCCESS: "DELETE_DOB_EXPENSE_SUCCESS",
  DELETE_DOB_EXPENSE_FAILURE: "DELETE_DOB_EXPENSE_FAILURE",
  DELETE_DOB_EXPENSE_RESTART: "DELETE_DOB_EXPENSE_RESTART",
  UPDATE_DOB_EXPENSE_START: "UPDATE_DOB_EXPENSE_START",
  UPDATE_DOB_EXPENSE_SUCCESS: "UPDATE_DOB_EXPENSE_SUCCESS",
  UPDATE_DOB_EXPENSE_FAILURE: "UPDATE_DOB_EXPENSE_FAILURE",
  UPDATE_DOB_EXPENSE_RESTART: "UPDATE_DOB_EXPENSE_RESTART",
  RETRIEVE_DOB_EXPENSES_START: "RETRIEVE_DOB_EXPENSES_START",
  RETRIEVE_DOB_EXPENSES_SUCCESS: "RETRIEVE_DOB_EXPENSES_SUCCESS",
  RETRIEVE_DOB_EXPENSES_FAILURE: "RETRIEVE_DOB_EXPENSES_FAILURE",
};

export default DobExpensesActionsTypes;
