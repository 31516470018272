import SuppliersActionsTypes from "./suppliers.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  suppliers: [],
};

const suppliersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SuppliersActionsTypes.RETRIEVE_SUPPLIERS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SuppliersActionsTypes.ADD_SUPPLIER_START:
    case SuppliersActionsTypes.DELETE_SUPPLIER_START:
    case SuppliersActionsTypes.UPDATE_SUPPLIER_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case SuppliersActionsTypes.RETRIEVE_SUPPLIERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        suppliers: action.payload,
      };
    case SuppliersActionsTypes.ADD_SUPPLIER_SUCCESS:
    case SuppliersActionsTypes.DELETE_SUPPLIER_SUCCESS:
    case SuppliersActionsTypes.UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case SuppliersActionsTypes.ADD_SUPPLIER_RESTART:
    case SuppliersActionsTypes.DELETE_SUPPLIER_RESTART:
    case SuppliersActionsTypes.UPDATE_SUPPLIER_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case SuppliersActionsTypes.ADD_SUPPLIER_FAILURE:
    case SuppliersActionsTypes.DELETE_SUPPLIER_FAILURE:
    case SuppliersActionsTypes.RETRIEVE_SUPPLIERS_FAILURE:
    case SuppliersActionsTypes.UPDATE_SUPPLIER_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default suppliersReducer;
