import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import FeedsRetrievalsActionsTypes from "./feeds-retrieval.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addFeedsRetrievalFailure,
  addFeedsRetrievalSuccess,
  deleteFeedsRetrievalFailure,
  deleteFeedsRetrievalSuccess,
  retrieveFeedsRetrievalsFailure,
  retrieveFeedsRetrievalsSuccess,
  updateFeedsRetrievalFailure,
  updateFeedsRetrievalSuccess,
} from "./feeds-retrieval.actions";

function* addFeedsRetrieval({
  payload: {
    actualBags,
    documentNo,
    drDate,
    driverName,
    driverSalary,
    fromFarm,
    helpers,
    remarks,
    toFarm,
    vehicleName,
  },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("feedsRetrievals").doc(id);

    yield reference.set({
      actualBags,
      date: new Date().toLocaleDateString(),
      documentNo,
      drDate,
      driverName,
      driverSalary,
      fromFarm,
      helpers,
      id,
      remarks,
      toFarm,
      vehicleName,
    });

    yield retrieveFeedsRetrievals();
    yield put(addFeedsRetrievalSuccess());
  } catch (error) {
    yield put(addFeedsRetrievalFailure(error.message));
  }
}

function* deleteFeedsRetrieval({ payload: { id } }) {
  try {
    yield firestore.collection("feedsRetrievals").doc(id).delete();

    yield retrieveFeedsRetrievals();
    yield put(deleteFeedsRetrievalSuccess());
  } catch (error) {
    yield put(deleteFeedsRetrievalFailure(error.message));
  }
}

function* retrieveFeedsRetrievals() {
  try {
    const reference = yield firestore.collection("feedsRetrievals");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveFeedsRetrievalsSuccess([]));
    } else {
      let feedsRetrievals = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        feedsRetrievals.push(data);
      });

      yield put(retrieveFeedsRetrievalsSuccess(feedsRetrievals));
    }
  } catch (error) {
    yield put(retrieveFeedsRetrievalsFailure(error.message));
  }
}

function* updateFeedsRetrieval({
  payload: {
    actualBags,
    documentNo,
    drDate,
    driverName,
    driverSalary,
    fromFarm,
    helpers,
    id,
    remarks,
    toFarm,
    vehicleName,
  },
}) {
  try {
    const reference = yield firestore.collection("feedsRetrievals").doc(id);

    yield reference.update({
      actualBags,
      documentNo,
      drDate,
      driverName,
      driverSalary,
      fromFarm,
      helpers,
      id,
      remarks,
      toFarm,
      vehicleName,
    });

    yield retrieveFeedsRetrievals();
    yield put(updateFeedsRetrievalSuccess());
  } catch (error) {
    yield put(updateFeedsRetrievalFailure(error.message));
  }
}

function* onAddFeedsRetrievalStart() {
  yield takeLatest(
    FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_START,
    addFeedsRetrieval
  );
}

function* onDeleteFeedsRetrievalStart() {
  yield takeLatest(
    FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_START,
    deleteFeedsRetrieval
  );
}

function* onRetrieveFeedsRetrievalsStart() {
  yield takeLatest(
    FeedsRetrievalsActionsTypes.RETRIEVE_FEEDS_RETRIEVALS_START,
    retrieveFeedsRetrievals
  );
}

function* onUpdateFeedsRetrievalStart() {
  yield takeLatest(
    FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_START,
    updateFeedsRetrieval
  );
}

export function* feedsRetrievalsSaga() {
  yield all([
    call(onAddFeedsRetrievalStart),
    call(onDeleteFeedsRetrievalStart),
    call(onRetrieveFeedsRetrievalsStart),
    call(onUpdateFeedsRetrievalStart),
  ]);
}
