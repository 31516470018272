const HelpersActionsTypes = {
  ADD_HELPER_START: "ADD_HELPER_START",
  ADD_HELPER_SUCCESS: "ADD_HELPER_SUCCESS",
  ADD_HELPER_FAILURE: "ADD_HELPER_FAILURE",
  ADD_HELPER_RESTART: "ADD_HELPER_RESTART",
  DELETE_HELPER_START: "DELETE_HELPER_START",
  DELETE_HELPER_SUCCESS: "DELETE_HELPER_SUCCESS",
  DELETE_HELPER_FAILURE: "DELETE_HELPER_FAILURE",
  DELETE_HELPER_RESTART: "DELETE_HELPER_RESTART",
  UPDATE_HELPER_START: "UPDATE_HELPER_START",
  UPDATE_HELPER_SUCCESS: "UPDATE_HELPER_SUCCESS",
  UPDATE_HELPER_FAILURE: "UPDATE_HELPER_FAILURE",
  UPDATE_HELPER_RESTART: "UPDATE_HELPER_RESTART",
  RETRIEVE_HELPERS_START: "RETRIEVE_HELPERS_START",
  RETRIEVE_HELPERS_SUCCESS: "RETRIEVE_HELPERS_SUCCESS",
  RETRIEVE_HELPERS_FAILURE: "RETRIEVE_HELPERS_FAILURE",
};

export default HelpersActionsTypes;
