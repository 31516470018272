import DobExpensesActionsTypes from "./dob-expense.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  dobExpenses: [],
};

const dobExpenseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DobExpensesActionsTypes.RETRIEVE_DOB_EXPENSES_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DobExpensesActionsTypes.ADD_DOB_EXPENSE_START:
    case DobExpensesActionsTypes.DELETE_DOB_EXPENSE_START:
    case DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case DobExpensesActionsTypes.RETRIEVE_DOB_EXPENSES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        dobExpenses: action.payload,
      };
    case DobExpensesActionsTypes.ADD_DOB_EXPENSE_SUCCESS:
    case DobExpensesActionsTypes.DELETE_DOB_EXPENSE_SUCCESS:
    case DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case DobExpensesActionsTypes.ADD_DOB_EXPENSE_RESTART:
    case DobExpensesActionsTypes.DELETE_DOB_EXPENSE_RESTART:
    case DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case DobExpensesActionsTypes.ADD_DOB_EXPENSE_FAILURE:
    case DobExpensesActionsTypes.DELETE_DOB_EXPENSE_FAILURE:
    case DobExpensesActionsTypes.RETRIEVE_DOB_EXPENSE_EXPENSES_FAILURE:
    case DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default dobExpenseReducer;
