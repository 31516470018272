import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import FeedsActionsTypes from "./feeds.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addFeedFailure,
  addFeedSuccess,
  deleteFeedFailure,
  deleteFeedSuccess,
  retrieveFeedsFailure,
  retrieveFeedsSuccess,
  updateFeedFailure,
  updateFeedSuccess,
} from "./feeds.actions";

function* addFeed({
  payload: {
    actualBags,
    docNo,
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    remarks,
    vehicleName,
  },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("feeds").doc(id);

    yield reference.set({
      actualBags,
      date: new Date().toLocaleDateString(),
      docNo,
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      remarks,
      vehicleName,
    });

    yield retrieveFeeds();
    yield put(addFeedSuccess());
  } catch (error) {
    yield put(addFeedFailure(error.message));
  }
}

function* deleteFeed({ payload: { id } }) {
  try {
    yield firestore.collection("feeds").doc(id).delete();

    yield retrieveFeeds();
    yield put(deleteFeedSuccess());
  } catch (error) {
    yield put(deleteFeedFailure(error.message));
  }
}

function* retrieveFeeds() {
  try {
    const reference = yield firestore.collection("feeds");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveFeedsSuccess([]));
    } else {
      let feeds = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        feeds.push(data);
      });

      yield put(retrieveFeedsSuccess(feeds));
    }
  } catch (error) {
    yield put(retrieveFeedsFailure(error.message));
  }
}

function* updateFeed({
  payload: {
    actualBags,
    docNo,
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    id,
    remarks,
    vehicleName,
  },
}) {
  try {
    const reference = yield firestore.collection("feeds").doc(id);

    yield reference.update({
      actualBags,
      docNo,
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      remarks,
      vehicleName,
    });

    yield retrieveFeeds();
    yield put(updateFeedSuccess());
  } catch (error) {
    yield put(updateFeedFailure(error.message));
  }
}

function* onAddFeedStart() {
  yield takeLatest(FeedsActionsTypes.ADD_FEED_START, addFeed);
}

function* onDeleteFeedStart() {
  yield takeLatest(FeedsActionsTypes.DELETE_FEED_START, deleteFeed);
}

function* onRetrieveFeedsStart() {
  yield takeLatest(FeedsActionsTypes.RETRIEVE_FEEDS_START, retrieveFeeds);
}

function* onUpdateFeedStart() {
  yield takeLatest(FeedsActionsTypes.UPDATE_FEED_START, updateFeed);
}

export function* feedsSaga() {
  yield all([
    call(onAddFeedStart),
    call(onDeleteFeedStart),
    call(onRetrieveFeedsStart),
    call(onUpdateFeedStart),
  ]);
}
