import DobsActionsTypes from "./dob.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  dobs: [],
};

const dobReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DobsActionsTypes.RETRIEVE_DOBS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DobsActionsTypes.ADD_DOB_START:
    case DobsActionsTypes.DELETE_DOB_START:
    case DobsActionsTypes.UPDATE_DOB_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case DobsActionsTypes.RETRIEVE_DOBS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        dobs: action.payload,
      };
    case DobsActionsTypes.ADD_DOB_SUCCESS:
    case DobsActionsTypes.DELETE_DOB_SUCCESS:
    case DobsActionsTypes.UPDATE_DOB_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case DobsActionsTypes.ADD_DOB_RESTART:
    case DobsActionsTypes.DELETE_DOB_RESTART:
    case DobsActionsTypes.UPDATE_DOB_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case DobsActionsTypes.ADD_DOB_FAILURE:
    case DobsActionsTypes.DELETE_DOB_FAILURE:
    case DobsActionsTypes.RETRIEVE_DOBS_FAILURE:
    case DobsActionsTypes.UPDATE_DOB_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default dobReducer;
