import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import {
  addSupplierRestart,
  addSupplierStart,
  updateSupplierRestart,
  updateSupplierStart,
} from "../../../../../../redux/suppliers/suppliers.actions";
import {
  selectAllSuppliers,
  selectIsActionLoading,
  selectIsSuccessful,
} from "../../../../../../redux/suppliers/suppliers.selectors";
import TextArea from "antd/es/input/TextArea";

const SupplierModal = ({
  addSupplierRestart,
  addSupplierStart,
  suppliers,
  data,
  error,
  isActionLoading,
  isEdit,
  isSuccessful,
  updateSupplierRestart,
  updateSupplierStart,
  visible,
  setIsEdit,
  setVisible,
}) => {
  const [codeCount, setCodeCount] = useState("001");

  useEffect(() => {
    if (suppliers.length > 0) {
      const suppliersCount = suppliers.length + 1;

      setCodeCount("00" + suppliersCount.toString());
    }
  }, [suppliers]);

  const onFinish = (values) => {
    const { businessAddress, code, contactNumber, counter, name } = values;
    const id = document.querySelector(".id").value;

    if (!isEdit) {
      addSupplierStart({ businessAddress, code, contactNumber, counter, name });
    } else {
      updateSupplierStart({
        businessAddress,
        code,
        contactNumber,
        counter,
        id,
        name,
      });
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccessful) {
      setVisible(false);
      setIsEdit(false);
      addSupplierRestart();
      return updateSupplierRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        setIsEdit(false);
      }
    }
  }, [
    addSupplierRestart,
    error,
    isSuccessful,
    updateSupplierRestart,
    setIsEdit,
    setVisible,
  ]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Manage Supplier Details"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <Form.Item
          label="Counter"
          name="counter"
          rules={[
            {
              required: true,
              message: "Please input counter!",
            },
          ]}
          initialValue={isEdit ? data.counter : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input code!",
            },
          ]}
          initialValue={isEdit ? data.code : codeCount}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Name of Supplier"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name of supplier!",
            },
          ]}
          initialValue={isEdit ? data.name : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Business Address"
          name="businessAddress"
          rules={[
            {
              required: true,
              message: "Please input business address!",
            },
          ]}
          initialValue={isEdit ? data.businessAddress : ""}
        >
          <TextArea rows={3} cols={4} />
        </Form.Item>
        <Form.Item
          label="Contact Number"
          name="contactNumber"
          rules={[
            {
              required: true,
              message: "Please input contact number!",
            },
          ]}
          initialValue={isEdit ? data.contactNumber : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isActionLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Update Supplier" : "Add Supplier"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  suppliers: selectAllSuppliers,
  isActionLoading: selectIsActionLoading,
  isSuccessful: selectIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  addSupplierRestart: () => dispatch(addSupplierRestart()),
  addSupplierStart: (data) => dispatch(addSupplierStart(data)),
  updateSupplierRestart: () => dispatch(updateSupplierRestart()),
  updateSupplierStart: (data) => dispatch(updateSupplierStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierModal);
