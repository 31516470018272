import { createSelector } from "reselect";

const selectHelpers = (state) => state.helpers;

export const selectIsActionLoading = createSelector(
  [selectHelpers],
  (helpers) => helpers.actionLoading
);

export const selectAllHelpers = createSelector(
  [selectHelpers],
  (helpers) => helpers.helpers
);

export const selectIsLoading = createSelector(
  [selectHelpers],
  (helpers) => helpers.loading
);

export const selectIsSuccessful = createSelector(
  [selectHelpers],
  (helpers) => helpers.isSuccessful
);

export const selectError = createSelector(
  [selectHelpers],
  (helpers) => helpers.error
);
