import DriversActionsTypes from "./drivers.types";

export const addDriverStart = (data) => ({
  type: DriversActionsTypes.ADD_DRIVER_START,
  payload: data,
});

export const addDriverSuccess = () => ({
  type: DriversActionsTypes.ADD_DRIVER_SUCCESS,
});

export const addDriverFailure = (error) => ({
  type: DriversActionsTypes.ADD_DRIVER_FAILURE,
  payload: error,
});

export const addDriverRestart = () => ({
  type: DriversActionsTypes.ADD_DRIVER_RESTART,
});

export const deleteDriverStart = (data) => ({
  type: DriversActionsTypes.DELETE_DRIVER_START,
  payload: data,
});

export const deleteDriverSuccess = () => ({
  type: DriversActionsTypes.DELETE_DRIVER_SUCCESS,
});

export const deleteDriverFailure = (error) => ({
  type: DriversActionsTypes.DELETE_DRIVER_FAILURE,
  payload: error,
});

export const deleteDriverRestart = () => ({
  type: DriversActionsTypes.DELETE_DRIVER_RESTART,
});

export const retrieveDriversStart = () => ({
  type: DriversActionsTypes.RETRIEVE_DRIVERS_START,
});

export const retrieveDriversSuccess = (data) => ({
  type: DriversActionsTypes.RETRIEVE_DRIVERS_SUCCESS,
  payload: data,
});

export const retrieveDriversFailure = (error) => ({
  type: DriversActionsTypes.RETRIEVE_DRIVERS_FAILURE,
  payload: error,
});

export const updateDriverStart = (data) => ({
  type: DriversActionsTypes.UPDATE_DRIVER_START,
  payload: data,
});

export const updateDriverSuccess = () => ({
  type: DriversActionsTypes.UPDATE_DRIVER_SUCCESS,
});

export const updateDriverFailure = (error) => ({
  type: DriversActionsTypes.UPDATE_DRIVER_FAILURE,
  payload: error,
});

export const updateDriverRestart = () => ({
  type: DriversActionsTypes.UPDATE_DRIVER_RESTART,
});
