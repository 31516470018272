import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import Select from "antd/es/select";
import {
  addFeedsListingRestart,
  addFeedsListingStart,
  updateFeedsListingRestart,
  updateFeedsListingStart,
} from "../../../../../../redux/feeds-listing/feeds-listing.actions";
import {
  selectIsActionLoading,
  selectIsSuccessful,
} from "../../../../../../redux/feeds-listing/feeds-listing.selectors";
import {
  ButtonContainer,
  DatePickerContainer,
  FormItemContainer,
  FormItem,
} from "./Modal.styles";
import { selectAllFarms } from "../../../../../../redux/farms/farms.selectors";
import { selectAllDrivers } from "../../../../../../redux/drivers/drivers.selectors";
import { selectAllVehicles } from "../../../../../../redux/vehicles/vehicles.selectors";
import HelperTable from "./HelperTable";
import { fireAlert } from "../../../../../../components";

const { Option } = Select;

const FeedsModal = ({
  addFeedsListingRestart,
  addFeedsListingStart,
  data,
  drivers,
  farms,
  error,
  helpersArray,
  isActionLoading,
  isEdit,
  isSuccessful,
  updateFeedsListingRestart,
  updateFeedsListingStart,
  vehicles,
  visible,
  setData,
  setHelpersArray,
  setHelperData,
  setIsEdit,
  setIsHelperEdit,
  setHelperModalVisible,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [drDate, setDrDate] = useState();
  const [driverSalary, setDriverSalary] = useState({
    allowance: 0.0,
    incentives: 0.0,
    others: 0.0,
    salary: 0.0,
    totalSalary: 0.0,
  });
  const [actualBags, setActualBags] = useState({
    actualBags: 0.0,
    bagsToBe: 0,
    handlingFee: 0.0,
    rate: 0.0,
    totalAmount: 0.0,
  });

  useEffect(() => {
    if (data) {
      if (data.helpers.length > 0) {
        setHelpersArray(data.helpers);
      }

      if (data.drDate) {
        setDrDate(data.drDate);
      }

      if (data.driverSalary) {
        setDriverSalary({
          allowance: data.driverSalary.allowance,
          incentives: data.driverSalary.incentives,
          others: data.driverSalary.others,
          salary: data.driverSalary.salary,
          totalSalary: data.driverSalary.totalSalary,
        });
      }

      if (data.actualBags) {
        setActualBags({
          actualBags: data.actualBags.actualBags,
          bagsToBe: data.actualBags.bagsToBe,
          handlingFee: data.actualBags.handlingFee,
          rate: data.actualBags.rate,
          totalAmount: data.actualBags.totalAmount,
        });
      }

      if (data.helpers.length > 0) {
        const getSumAmount = (items, property) =>
          items.reduce((a, b) => a + b[property], 0.0);

        if (helpersArray.length > 0) {
          const totalHelperSalary = getSumAmount(
            data.helpers,
            "salary"
          ).toFixed(2);
          const totalHelperIncentives = getSumAmount(
            data.helpers,
            "incentives"
          ).toFixed(2);
          const totalHelperAllowances = getSumAmount(
            data.helpers,
            "allowances"
          ).toFixed(2);
          const totalHelperOthers = getSumAmount(
            data.helpers,
            "others"
          ).toFixed(2);
          const grossAmount = getSumAmount(data.helpers, "total").toFixed(2);

          form.setFieldsValue({
            totalHelperSalary,
            totalHelperIncentives,
            totalHelperAllowances,
            totalHelperOthers,
            grossAmount,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      totalDriverSalary: parseFloat(driverSalary.totalSalary).toFixed(2),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverSalary.totalSalary]);

  useEffect(() => {
    form.setFieldsValue({
      totalAmount: parseFloat(actualBags.totalAmount).toFixed(2),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualBags.totalAmount]);

  useEffect(() => {
    const getSumAmount = (items, property) =>
      items.reduce((a, b) => a + b[property], 0.0);

    if (helpersArray.length > 0) {
      const totalHelperSalary = getSumAmount(helpersArray, "salary").toFixed(2);
      const totalHelperIncentives = getSumAmount(
        helpersArray,
        "incentives"
      ).toFixed(2);
      const totalHelperAllowances = getSumAmount(
        helpersArray,
        "allowances"
      ).toFixed(2);
      const totalHelperOthers = getSumAmount(helpersArray, "others").toFixed(2);
      const grossAmount = getSumAmount(helpersArray, "total").toFixed(2);

      form.setFieldsValue({
        totalHelperSalary,
        totalHelperIncentives,
        totalHelperAllowances,
        totalHelperOthers,
        grossAmount,
      });
    } else {
      form.setFieldsValue({
        totalHelperSalary: 0.0,
        totalHelperIncentives: 0.0,
        totalHelperAllowances: 0.0,
        totalHelperOthers: 0.0,
        grossAmount: 0.0,
      });
    }
  }, [form, helpersArray]);

  useEffect(() => {
    if (isSuccessful) {
      setVisible(false);
      setIsEdit(false);
      addFeedsListingRestart();
      setData(null);
      setDriverSalary({
        allowance: 0.0,
        incentives: 0.0,
        others: 0.0,
        salary: 0.0,
        totalSalary: 0.0,
      });
      setActualBags({
        actualBags: 0.0,
        bagsToBe: 0,
        handlingFee: 0.0,
        rate: 0.0,
        totalAmount: 0.0,
      });
      return updateFeedsListingRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        setIsEdit(false);
        setData(null);
        setDriverSalary({
          allowance: 0.0,
          incentives: 0.0,
          others: 0.0,
          salary: 0.0,
          totalSalary: 0.0,
        });
        setActualBags({
          actualBags: 0.0,
          bagsToBe: 0,
          handlingFee: 0.0,
          rate: 0.0,
          totalAmount: 0.0,
        });
      }
    }
  }, [
    addFeedsListingRestart,
    error,
    isSuccessful,
    updateFeedsListingRestart,
    setData,
    setIsEdit,
    setVisible,
  ]);

  const onFinish = (values) => {
    const { documentNo, driverName, farmName, remarks, vehicleName } = values;
    const id = document.querySelector(".id").value;

    if (helpersArray.length <= 0) {
      return fireAlert("No helper found!", "error");
    }

    if (!isEdit) {
      addFeedsListingStart({
        actualBags,
        documentNo,
        drDate,
        driverName,
        driverSalary,
        farmName,
        helpers: helpersArray,
        remarks,
        vehicleName,
      });
    } else {
      updateFeedsListingStart({
        actualBags,
        documentNo,
        drDate,
        driverName,
        driverSalary,
        farmName,
        helpers: helpersArray,
        id,
        remarks,
        vehicleName,
      });
    }
  };

  const handleCancel = () => {
    setData(null);
    setIsEdit(false);
    setVisible(false);
    setHelpersArray([]);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const updateDriverSalary = (field, value) => {
    if (value === "0" || value === "") {
      if (field === "salary") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(0.0),
          totalSalary:
            driverSalary.allowance +
            driverSalary.incentives +
            driverSalary.others +
            parseFloat(0.0),
        });
      } else if (field === "incentives") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(0.0),
          totalSalary:
            driverSalary.allowance +
            parseFloat(0.0) +
            driverSalary.others +
            driverSalary.salary,
        });
      } else if (field === "allowance") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(0.0),
          totalSalary:
            parseFloat(0.0) +
            driverSalary.incentives +
            driverSalary.others +
            driverSalary.salary,
        });
      } else if (field === "others") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(0.0),
          totalSalary:
            driverSalary.allowance +
            driverSalary.incentives +
            parseFloat(0.0) +
            driverSalary.salary,
        });
      }
    } else {
      if (field === "salary") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(value),
          totalSalary:
            driverSalary.allowance +
            driverSalary.incentives +
            driverSalary.others +
            parseFloat(value),
        });
      } else if (field === "incentives") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(value),
          totalSalary:
            driverSalary.allowance +
            parseFloat(value) +
            driverSalary.others +
            driverSalary.salary,
        });
      } else if (field === "allowance") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(value),
          totalSalary:
            parseFloat(value) +
            driverSalary.incentives +
            driverSalary.others +
            driverSalary.salary,
        });
      } else if (field === "others") {
        setDriverSalary({
          ...driverSalary,
          [field]: parseFloat(value),
          totalSalary:
            driverSalary.allowance +
            driverSalary.incentives +
            parseFloat(value) +
            driverSalary.salary,
        });
      }
    }
  };

  const updateActualBags = (field, value) => {
    if (value === "0" || value === "") {
      if (field === "actualBags") {
        setActualBags({
          ...actualBags,
          [field]: parseInt(0),
          totalAmount:
            actualBags.bagsToBe * actualBags.rate + actualBags.handlingFee,
        });
      } else if (field === "bagsToBe") {
        setActualBags({
          ...actualBags,
          [field]: parseInt(0),
          totalAmount: parseInt(0) * actualBags.rate + actualBags.handlingFee,
        });
      } else if (field === "handlingFee") {
        setActualBags({
          ...actualBags,
          [field]: parseFloat(0.0),
          totalAmount: actualBags.bagsToBe * actualBags.rate + parseFloat(0.0),
        });
      } else if (field === "rate") {
        setActualBags({
          ...actualBags,
          [field]: parseFloat(0.0),
          totalAmount:
            actualBags.bagsToBe * parseFloat(0.0) + actualBags.handlingFee,
        });
      }
    } else {
      if (field === "actualBags") {
        setActualBags({
          ...actualBags,
          [field]: parseInt(value),
          totalAmount:
            actualBags.bagsToBe * actualBags.rate + actualBags.handlingFee,
        });
      } else if (field === "bagsToBe") {
        setActualBags({
          ...actualBags,
          [field]: parseInt(value),
          totalAmount:
            parseInt(value) * actualBags.rate + actualBags.handlingFee,
        });
      } else if (field === "handlingFee") {
        setActualBags({
          ...actualBags,
          [field]: parseFloat(value),
          totalAmount:
            +actualBags.bagsToBe * actualBags.rate + parseFloat(value),
        });
      } else if (field === "rate") {
        setActualBags({
          ...actualBags,
          [field]: parseFloat(value),
          totalAmount:
            actualBags.bagsToBe * parseFloat(value) + actualBags.handlingFee,
        });
      }
    }
  };

  const datePickerChange = (date, dateString) => setDrDate(dateString);

  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={handleCancel}
      style={{ top: 20 }}
      title="Manage Feeds Listing Details"
      visible={visible}
      width={1000}
    >
      <Form {...layout} form={form} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <FormItemContainer>
          <FormItem
            label="Document No."
            name="documentNo"
            rules={[
              {
                required: true,
                message: "Please input number!",
              },
            ]}
            initialValue={isEdit ? data.documentNo : ""}
          >
            <Input />
          </FormItem>
          <FormItem
            label="DR Date"
            name="drDate"
            rules={[
              {
                required: true,
                message: "Please input DR date!",
              },
            ]}
            initialValue={isEdit ? moment(data.drDate) : ""}
          >
            <DatePickerContainer onChange={datePickerChange} />
          </FormItem>
        </FormItemContainer>
        <FormItemContainer>
          <FormItem
            label="Name of Farm"
            name="farmName"
            rules={[
              {
                required: true,
                message: "Please select name of farm!",
              },
            ]}
            initialValue={isEdit ? data.farmName : ""}
          >
            <Select>
              {farms &&
                farms.map((farm) => (
                  <Option value={farm.name}>{farm.name}</Option>
                ))}
            </Select>
          </FormItem>
        </FormItemContainer>
        <FormItemContainer>
          <FormItem
            label="Name of Driver"
            name="driverName"
            rules={[
              {
                required: true,
                message: "Please select name of driver!",
              },
            ]}
            initialValue={isEdit ? data.driverName : ""}
          >
            <Select>
              {drivers &&
                drivers.map((driver) => (
                  <Option value={driver.name}>{driver.name}</Option>
                ))}
            </Select>
          </FormItem>
          <FormItem
            label="Name of Vehicle"
            name="vehicleName"
            rules={[
              {
                required: true,
                message: "Please select name of vehicle!",
              },
            ]}
            initialValue={isEdit ? data.vehicleName : ""}
          >
            <Select>
              {vehicles &&
                vehicles.map((vehicle) => (
                  <Option value={vehicle.name}>{vehicle.name}</Option>
                ))}
            </Select>
          </FormItem>
        </FormItemContainer>
        <FormItemContainer>
          <FormItem
            label="Driver Salary"
            name="driverSalary"
            rules={[
              {
                required: true,
                message: "Please input salary!",
              },
            ]}
            initialValue={
              isEdit ? parseFloat(data.driverSalary.salary).toFixed(2) : ""
            }
          >
            <Input
              onChange={(e) => updateDriverSalary("salary", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Incentives"
            name="incentives"
            rules={[
              {
                required: true,
                message: "Please input incentives!",
              },
            ]}
            initialValue={
              isEdit ? parseFloat(data.driverSalary.incentives).toFixed(2) : ""
            }
          >
            <Input
              onChange={(e) => updateDriverSalary("incentives", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Allowance"
            name="allowance"
            rules={[
              {
                required: true,
                message: "Please input allowance!",
              },
            ]}
            initialValue={
              isEdit ? parseFloat(data.driverSalary.allowance).toFixed(2) : ""
            }
          >
            <Input
              onChange={(e) => updateDriverSalary("allowance", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Others"
            name="others"
            rules={[
              {
                required: true,
                message: "Please input others!",
              },
            ]}
            initialValue={
              isEdit ? parseFloat(data.driverSalary.others).toFixed(2) : ""
            }
          >
            <Input
              onChange={(e) => updateDriverSalary("others", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Total Amount"
            name="totalDriverSalary"
            initialValue={
              isEdit
                ? parseFloat(data.driverSalary.totalSalary).toFixed(2)
                : "0.00"
            }
          >
            <Input readOnly type="number" />
          </FormItem>
        </FormItemContainer>
        <FormItemContainer>
          <FormItem
            label="Remarks / Particulars"
            name="remarks"
            rules={[
              {
                required: true,
                message: "Please input remarks or particulars!",
              },
            ]}
            initialValue={isEdit ? data.remarks : ""}
          >
            <Input />
          </FormItem>
        </FormItemContainer>
        <FormItemContainer>
          <FormItem
            label="Actual Bags"
            name="actualBags"
            rules={[
              {
                required: true,
                message: "Please input actual bags!",
              },
            ]}
            initialValue={isEdit ? data.actualBags.actualBags : ""}
          >
            <Input
              onChange={(e) => updateActualBags("actualBags", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Bags to be"
            name="bagsToBe"
            rules={[
              {
                required: true,
                message: "Please input bags to be!",
              },
            ]}
            initialValue={isEdit ? data.actualBags.bagsToBe : ""}
          >
            <Input
              onChange={(e) => updateActualBags("bagsToBe", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Handling Fee"
            name="handlingFee"
            rules={[
              {
                required: true,
                message: "Please input handling fee!",
              },
            ]}
            initialValue={isEdit ? data.actualBags.handlingFee : ""}
          >
            <Input
              onChange={(e) => updateActualBags("handlingFee", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Rate"
            name="rate"
            rules={[
              {
                required: true,
                message: "Please input rate!",
              },
            ]}
            initialValue={
              isEdit ? parseFloat(data.actualBags.rate).toFixed(2) : ""
            }
          >
            <Input
              onChange={(e) => updateActualBags("rate", e.target.value)}
              type="number"
            />
          </FormItem>
          <FormItem
            label="Total Amount"
            name="totalAmount"
            initialValue={
              isEdit
                ? parseFloat(data.actualBags.totalAmount).toFixed(2)
                : "0.00"
            }
          >
            <Input readOnly type="number" />
          </FormItem>
        </FormItemContainer>
        <Form.Item>
          <HelperTable
            helpers={helpersArray}
            setData={setHelperData}
            setIsEdit={setIsHelperEdit}
            setHelpersArray={setHelpersArray}
            setVisible={setHelperModalVisible}
          />
        </Form.Item>
        <FormItemContainer>
          <FormItem
            label="Total Salary"
            name="totalHelperSalary"
            initialValue={"0.00"}
          >
            <Input readOnly type="number" />
          </FormItem>
          <FormItem
            label="Total Incentives"
            name="totalHelperIncentives"
            initialValue={"0.00"}
          >
            <Input readOnly type="number" />
          </FormItem>
          <FormItem
            label="Total Allowances"
            name="totalHelperAllowances"
            initialValue={"0.00"}
          >
            <Input readOnly type="number" />
          </FormItem>
          <FormItem
            label="Total Others"
            name="totalHelperOthers"
            initialValue={"0.00"}
          >
            <Input readOnly type="number" />
          </FormItem>
          <FormItem
            label="Gross Amount"
            name="grossAmount"
            initialValue={"0.00"}
          >
            <Input readOnly type="number" />
          </FormItem>
        </FormItemContainer>
        <Form.Item>
          <ButtonContainer>
            <Button
              onClick={() => setHelperModalVisible(true)}
              style={{
                borderRadius: 5,
                border: "none",
                marginRight: 5,
              }}
              type="primary"
            >
              Add Helper
            </Button>
            <Button
              loading={isActionLoading}
              style={{
                borderRadius: 5,
                border: "none",
              }}
              type="primary"
              htmlType="submit"
            >
              {isEdit ? "Update Feeds Listing" : "Add Feeds Listing"}
            </Button>
          </ButtonContainer>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  drivers: selectAllDrivers,
  farms: selectAllFarms,
  isActionLoading: selectIsActionLoading,
  isSuccessful: selectIsSuccessful,
  vehicles: selectAllVehicles,
});

const mapDispatchToProps = (dispatch) => ({
  addFeedsListingRestart: () => dispatch(addFeedsListingRestart()),
  addFeedsListingStart: (data) => dispatch(addFeedsListingStart(data)),
  updateFeedsListingRestart: () => dispatch(updateFeedsListingRestart()),
  updateFeedsListingStart: (data) => dispatch(updateFeedsListingStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedsModal);
