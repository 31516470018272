import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import OthersDeliveryActionsTypes from "./others-delivery.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addOthersDeliveryFailure,
  addOthersDeliverySuccess,
  deleteOthersDeliveryFailure,
  deleteOthersDeliverySuccess,
  retrieveOthersDeliveryFailure,
  retrieveOthersDeliverySuccess,
  updateOthersDeliveryFailure,
  updateOthersDeliverySuccess,
} from "./others-delivery.actions";

function* addOthersDelivery({
  payload: {
    customerName,
    drDate,
    driverName,
    driverSalary,
    drNo,
    minimumCapacity,
    helpers,
    placeDelivered,
    remarks,
    vehicleName,
  },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("othersDelivery").doc(id);

    yield reference.set({
      customerName,
      date: new Date().toLocaleDateString(),
      drDate,
      driverName,
      driverSalary,
      drNo,
      minimumCapacity,
      helpers,
      id,
      placeDelivered,
      remarks,
      vehicleName,
    });

    yield retrieveOthersDelivery();
    yield put(addOthersDeliverySuccess());
  } catch (error) {
    yield put(addOthersDeliveryFailure(error.message));
  }
}

function* deleteOthersDelivery({ payload: { id } }) {
  try {
    yield firestore.collection("othersDelivery").doc(id).delete();

    yield retrieveOthersDelivery();
    yield put(deleteOthersDeliverySuccess());
  } catch (error) {
    yield put(deleteOthersDeliveryFailure(error.message));
  }
}

function* retrieveOthersDelivery() {
  try {
    const reference = yield firestore.collection("othersDelivery");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveOthersDeliverySuccess([]));
    } else {
      let othersDelivery = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        othersDelivery.push(data);
      });

      yield put(retrieveOthersDeliverySuccess(othersDelivery));
    }
  } catch (error) {
    yield put(retrieveOthersDeliveryFailure(error.message));
  }
}

function* updateOthersDelivery({
  payload: {
    customerName,
    drDate,
    driverName,
    driverSalary,
    drNo,
    minimumCapacity,
    helpers,
    id,
    placeDelivered,
    remarks,
    vehicleName,
  },
}) {
  try {
    const reference = yield firestore.collection("othersDelivery").doc(id);

    yield reference.update({
      customerName,
      drDate,
      driverName,
      driverSalary,
      drNo,
      minimumCapacity,
      helpers,
      id,
      placeDelivered,
      remarks,
      vehicleName,
    });

    yield retrieveOthersDelivery();
    yield put(updateOthersDeliverySuccess());
  } catch (error) {
    yield put(updateOthersDeliveryFailure(error.message));
  }
}

function* onAddOthersDeliveryStart() {
  yield takeLatest(
    OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_START,
    addOthersDelivery
  );
}

function* onDeleteOthersDeliveryStart() {
  yield takeLatest(
    OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_START,
    deleteOthersDelivery
  );
}

function* onRetrieveOthersDeliveryStart() {
  yield takeLatest(
    OthersDeliveryActionsTypes.RETRIEVE_OTHERS_DELIVERY_START,
    retrieveOthersDelivery
  );
}

function* onUpdateOthersDeliveryStart() {
  yield takeLatest(
    OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_START,
    updateOthersDelivery
  );
}

export function* othersDeliverySaga() {
  yield all([
    call(onAddOthersDeliveryStart),
    call(onDeleteOthersDeliveryStart),
    call(onRetrieveOthersDeliveryStart),
    call(onUpdateOthersDeliveryStart),
  ]);
}
