import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import {
  addHelperRestart,
  addHelperStart,
  updateHelperRestart,
  updateHelperStart,
} from "../../../../../../redux/helpers/helpers.actions";
import {
  selectAllHelpers,
  selectIsActionLoading,
  selectIsSuccessful,
} from "../../../../../../redux/helpers/helpers.selectors";
import TextArea from "antd/es/input/TextArea";

const CustomerModal = ({
  addHelperRestart,
  addHelperStart,
  helpers,
  data,
  error,
  isActionLoading,
  isEdit,
  isSuccessful,
  updateHelperRestart,
  updateHelperStart,
  visible,
  setIsEdit,
  setVisible,
}) => {
  const [codeCount, setCodeCount] = useState("001");

  useEffect(() => {
    if (helpers.length > 0) {
      const helpersCount = helpers.length + 1;

      setCodeCount("00" + helpersCount.toString());
    }
  }, [helpers]);

  const onFinish = (values) => {
    const { code, counter, contactNumber, homeAddress, name } = values;
    const id = document.querySelector(".id").value;

    if (!isEdit) {
      addHelperStart({ code, counter, contactNumber, homeAddress, name });
    } else {
      updateHelperStart({
        code,
        counter,
        contactNumber,
        homeAddress,
        id,
        name,
      });
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccessful) {
      setVisible(false);
      setIsEdit(false);
      addHelperRestart();
      return updateHelperRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        setIsEdit(false);
      }
    }
  }, [
    addHelperRestart,
    error,
    isSuccessful,
    updateHelperRestart,
    setIsEdit,
    setVisible,
  ]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Manage Helper Details"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <Form.Item
          label="Counter"
          name="counter"
          rules={[
            {
              required: true,
              message: "Please input counter!",
            },
          ]}
          initialValue={isEdit ? data.counter : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input code!",
            },
          ]}
          initialValue={isEdit ? data.code : codeCount}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Name of Helper"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name of helper!",
            },
          ]}
          initialValue={isEdit ? data.name : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Home Address"
          name="homeAddress"
          rules={[
            {
              required: true,
              message: "Please input home address!",
            },
          ]}
          initialValue={isEdit ? data.homeAddress : ""}
        >
          <TextArea rows={3} cols={4} />
        </Form.Item>
        <Form.Item
          label="Contact Number"
          name="contactNumber"
          rules={[
            {
              required: true,
              message: "Please input contact number!",
            },
          ]}
          initialValue={isEdit ? data.contactNumber : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isActionLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Update Helper" : "Add Helper"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  helpers: selectAllHelpers,
  isActionLoading: selectIsActionLoading,
  isSuccessful: selectIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  addHelperRestart: () => dispatch(addHelperRestart()),
  addHelperStart: (data) => dispatch(addHelperStart(data)),
  updateHelperRestart: () => dispatch(updateHelperRestart()),
  updateHelperStart: (data) => dispatch(updateHelperStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModal);
