import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import FarmsActionsTypes from "./farms.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addFarmFailure,
  addFarmSuccess,
  deleteFarmFailure,
  deleteFarmSuccess,
  retrieveFarmsFailure,
  retrieveFarmsSuccess,
  updateFarmFailure,
  updateFarmSuccess,
} from "./farms.actions";

function* addFarm({ payload: { code, location, name } }) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("farms").doc(id);

    yield reference.set({ code, id, location, name });

    yield retrieveFarms();
    yield put(addFarmSuccess());
  } catch (error) {
    yield put(addFarmFailure(error.message));
  }
}

function* deleteFarm({ payload: { id } }) {
  try {
    yield firestore.collection("farms").doc(id).delete();

    yield retrieveFarms();
    yield put(deleteFarmSuccess());
  } catch (error) {
    yield put(deleteFarmFailure(error.message));
  }
}

function* retrieveFarms() {
  try {
    const reference = yield firestore.collection("farms");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveFarmsSuccess([]));
    } else {
      let farms = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        farms.push(data);
      });

      yield put(retrieveFarmsSuccess(farms));
    }
  } catch (error) {
    yield put(retrieveFarmsFailure(error.message));
  }
}

function* updateFarm({ payload: { code, location, id, name } }) {
  try {
    const reference = yield firestore.collection("farms").doc(id);

    yield reference.update({ code, location, id, name });

    yield retrieveFarms();
    yield put(updateFarmSuccess());
  } catch (error) {
    yield put(updateFarmFailure(error.message));
  }
}

function* onAddFarmStart() {
  yield takeLatest(FarmsActionsTypes.ADD_FARM_START, addFarm);
}

function* onDeleteFarmStart() {
  yield takeLatest(FarmsActionsTypes.DELETE_FARM_START, deleteFarm);
}

function* onRetrieveFarmStart() {
  yield takeLatest(FarmsActionsTypes.RETRIEVE_FARMS_START, retrieveFarms);
}

function* onUpdateFarmStart() {
  yield takeLatest(FarmsActionsTypes.UPDATE_FARM_START, updateFarm);
}

export function* farmsSaga() {
  yield all([
    call(onAddFarmStart),
    call(onDeleteFarmStart),
    call(onRetrieveFarmStart),
    call(onUpdateFarmStart),
  ]);
}
