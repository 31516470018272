import VehiclesActionsTypes from "./vehicles.types";

export const addVehicleStart = (data) => ({
  type: VehiclesActionsTypes.ADD_VEHICLE_START,
  payload: data,
});

export const addVehicleSuccess = () => ({
  type: VehiclesActionsTypes.ADD_VEHICLE_SUCCESS,
});

export const addVehicleFailure = (error) => ({
  type: VehiclesActionsTypes.ADD_VEHICLE_FAILURE,
  payload: error,
});

export const addVehicleRestart = () => ({
  type: VehiclesActionsTypes.ADD_VEHICLE_RESTART,
});

export const deleteVehicleStart = (data) => ({
  type: VehiclesActionsTypes.DELETE_VEHICLE_START,
  payload: data,
});

export const deleteVehicleSuccess = () => ({
  type: VehiclesActionsTypes.DELETE_VEHICLE_SUCCESS,
});

export const deleteVehicleFailure = (error) => ({
  type: VehiclesActionsTypes.DELETE_VEHICLE_FAILURE,
  payload: error,
});

export const deleteVehicleRestart = () => ({
  type: VehiclesActionsTypes.DELETE_VEHICLE_RESTART,
});

export const retrieveVehiclesStart = () => ({
  type: VehiclesActionsTypes.RETRIEVE_VEHICLES_START,
});

export const retrieveVehiclesSuccess = (data) => ({
  type: VehiclesActionsTypes.RETRIEVE_VEHICLES_SUCCESS,
  payload: data,
});

export const retrieveVehiclesFailure = (error) => ({
  type: VehiclesActionsTypes.RETRIEVE_VEHICLES_FAILURE,
  payload: error,
});

export const updateVehicleStart = (data) => ({
  type: VehiclesActionsTypes.UPDATE_VEHICLE_START,
  payload: data,
});

export const updateVehicleSuccess = () => ({
  type: VehiclesActionsTypes.UPDATE_VEHICLE_SUCCESS,
});

export const updateVehicleFailure = (error) => ({
  type: VehiclesActionsTypes.UPDATE_VEHICLE_FAILURE,
  payload: error,
});

export const updateVehicleRestart = () => ({
  type: VehiclesActionsTypes.UPDATE_VEHICLE_RESTART,
});
