import React, { Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Input from "antd/es/input";
import Menu from "antd/es/menu";
import notification from "antd/es/notification";
import Skeleton from "antd/es/skeleton";
import Space from "antd/es/space";
import Spin from "antd/es/spin";
import Table from "antd/es/table";
import Highlighter from "react-highlight-words";
import {
  deleteFeedsListingRestart,
  deleteFeedsListingStart,
  retrieveFeedsListingsStart,
} from "../../../../../../redux/feeds-listing/feeds-listing.actions";
import { retrieveDriversStart } from "../../../../../../redux/drivers/drivers.actions";
import { retrieveFarmsStart } from "../../../../../../redux/farms/farms.actions";
import { retrieveHelpersStart } from "../../../../../../redux/helpers/helpers.actions";
import { retrieveVehiclesStart } from "../../../../../../redux/vehicles/vehicles.actions";
import {
  selectAllFeedsListings,
  selectError,
  selectIsActionLoading,
  selectIsLoading,
  selectIsSuccessful,
} from "../../../../../../redux/feeds-listing/feeds-listing.selectors";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { fireAlertWithConfirmation } from "../../../../../../components";

export class FeedsTable extends React.Component {
  state = {
    feedsListings: [],
    searchedColumn: "",
    searchText: "",
  };

  componentDidMount() {
    const {
      retrieveFeedsListingsStart,
      retrieveDriversStart,
      retrieveFarmsStart,
      retrieveHelpersStart,
      retrieveVehiclesStart,
    } = this.props;

    retrieveFeedsListingsStart();
    retrieveDriversStart();
    retrieveFarmsStart();
    retrieveHelpersStart();
    retrieveVehiclesStart();
  }

  componentDidUpdate(previousProps) {
    const { deleteFeedsListingRestart, error, isSuccessful } = this.props;

    if (this.props.feedsListings !== previousProps.feedsListings) {
      this.setState({
        feedsListings: this.props.feedsListings,
      });
    }

    if (isSuccessful) {
      deleteFeedsListingRestart();
      this.openNotificationSuccess();
    }

    if (error !== null) {
      this.openNotificationError(error);
    }
  }

  openNotificationSuccess = () => {
    notification.success({
      message: `Success!`,
      duration: 5,
      description: "You have successfully managed a feeds listing data!",
      placement: "topRight",
    });
  };

  openNotificationError = (message) => {
    notification.error({
      message: `Uh-oh!`,
      duration: 5,
      description: message,
      placement: "topRight",
    });
  };

  updateData = (data) => {
    const { setIsEdit, setData, setVisible } = this.props;

    setIsEdit(true);
    setData(data);
    setVisible(true);
  };

  deleteData = (data) => {
    const { deleteFeedsListingStart } = this.props;

    fireAlertWithConfirmation(
      `Are you sure you want to delete the selected feeds listing? This action can't be UNDONE!`,
      "The selected feeds listing has been successfully deleted!",
      (confirmed) => {
        if (confirmed) {
          deleteFeedsListingStart(data);
        } else {
          return false;
        }
      }
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => (this.searchInput = node)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const { feedsListings } = this.state;
    const { isActionLoading, isLoading } = this.props;

    const columns = [
      {
        title: "Doc No.",
        dataIndex: "documentNo",
        key: "documentNo",
        ...this.getColumnSearchProps("documentNo"),
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        ...this.getColumnSearchProps("date"),
      },
      {
        title: "Name of Farm",
        dataIndex: "farmName",
        key: "farmName",
        ...this.getColumnSearchProps("farmName"),
      },
      {
        title: "Actual Bags",
        dataIndex: ["actualBags", "actualBags"],
        key: "actualBags",
        ...this.getColumnSearchProps("actualBags"),
      },
      {
        title: "Bags to be",
        dataIndex: ["actualBags", "bagsToBe"],
        key: "bagsToBe",
        ...this.getColumnSearchProps("bagsToBe"),
      },
      {
        title: "Rate",
        dataIndex: ["actualBags", "rate"],
        key: "rate",
        ...this.getColumnSearchProps("rate"),
        render: (data) => data.toFixed(2),
      },
      {
        title: "Handling Fee",
        dataIndex: ["actualBags", "handlingFee"],
        key: "handlingFee",
        ...this.getColumnSearchProps("handlingFee"),
        render: (data) => data.toFixed(2),
      },
      {
        title: "Total Amount",
        dataIndex: ["actualBags", "totalAmount"],
        key: "totalAmount",
        ...this.getColumnSearchProps("totalAmount"),
        render: (data) => data.toFixed(2),
      },
      {
        title: "Action",
        key: "action",
        render: (data) => (
          <span>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => this.updateData(data)}>
                    <EditOutlined /> Update
                  </Menu.Item>
                  <Menu.Item onClick={() => this.deleteData(data)}>
                    <DeleteOutlined /> Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button type="default" icon={<MoreOutlined />} />
            </Dropdown>
          </span>
        ),
      },
    ];
    return (
      <Fragment>
        <Spin spinning={isActionLoading}>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Table
              columns={columns}
              dataSource={feedsListings && feedsListings}
              pagination={{ defaultPageSize: 7 }}
            />
          )}
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  feedsListings: selectAllFeedsListings,
  error: selectError,
  isActionLoading: selectIsActionLoading,
  isLoading: selectIsLoading,
  isSuccessful: selectIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFeedsListingRestart: () => dispatch(deleteFeedsListingRestart()),
  deleteFeedsListingStart: (data) => dispatch(deleteFeedsListingStart(data)),
  retrieveFeedsListingsStart: () => dispatch(retrieveFeedsListingsStart()),
  retrieveDriversStart: () => dispatch(retrieveDriversStart()),
  retrieveFarmsStart: () => dispatch(retrieveFarmsStart()),
  retrieveHelpersStart: () => dispatch(retrieveHelpersStart()),
  retrieveVehiclesStart: () => dispatch(retrieveVehiclesStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedsTable);
