const FeedsListingActionsTypes = {
  ADD_FEEDS_LISTING_START: "ADD_FEEDS_LISTING_START",
  ADD_FEEDS_LISTING_SUCCESS: "ADD_FEEDS_LISTING_SUCCESS",
  ADD_FEEDS_LISTING_FAILURE: "ADD_FEEDS_LISTING_FAILURE",
  ADD_FEEDS_LISTING_RESTART: "ADD_FEEDS_LISTING_RESTART",
  DELETE_FEEDS_LISTING_START: "DELETE_FEEDS_LISTING_START",
  DELETE_FEEDS_LISTING_SUCCESS: "DELETE_FEEDS_LISTING_SUCCESS",
  DELETE_FEEDS_LISTING_FAILURE: "DELETE_FEEDS_LISTING_FAILURE",
  DELETE_FEEDS_LISTING_RESTART: "DELETE_FEEDS_LISTING_RESTART",
  UPDATE_FEEDS_LISTING_START: "UPDATE_FEEDS_LISTING_START",
  UPDATE_FEEDS_LISTING_SUCCESS: "UPDATE_FEEDS_LISTING_SUCCESS",
  UPDATE_FEEDS_LISTING_FAILURE: "UPDATE_FEEDS_LISTING_FAILURE",
  UPDATE_FEEDS_LISTING_RESTART: "UPDATE_FEEDS_LISTING_RESTART",
  RETRIEVE_FEEDS_LISTINGS_START: "RETRIEVE_FEEDS_LISTINGS_START",
  RETRIEVE_FEEDS_LISTINGS_SUCCESS: "RETRIEVE_FEEDS_LISTINGS_SUCCESS",
  RETRIEVE_FEEDS_LISTINGS_FAILURE: "RETRIEVE_FEEDS_LISTINGS_FAILURE",
};

export default FeedsListingActionsTypes;
