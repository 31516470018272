import { createSelector } from "reselect";

const selectDrivers = (state) => state.drivers;

export const selectIsActionLoading = createSelector(
  [selectDrivers],
  (drivers) => drivers.actionLoading
);

export const selectAllDrivers = createSelector(
  [selectDrivers],
  (drivers) => drivers.drivers
);

export const selectIsLoading = createSelector(
  [selectDrivers],
  (drivers) => drivers.loading
);

export const selectIsSuccessful = createSelector(
  [selectDrivers],
  (drivers) => drivers.isSuccessful
);

export const selectError = createSelector(
  [selectDrivers],
  (drivers) => drivers.error
);
