import OthersDeliveryActionsTypes from "./others-delivery.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  othersDelivery: [],
};

const othersDeliveryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OthersDeliveryActionsTypes.RETRIEVE_OTHERS_DELIVERY_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_START:
    case OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_START:
    case OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case OthersDeliveryActionsTypes.RETRIEVE_OTHERS_DELIVERY_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        othersDelivery: action.payload,
      };
    case OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_SUCCESS:
    case OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_SUCCESS:
    case OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_RESTART:
    case OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_RESTART:
    case OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_FAILURE:
    case OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_FAILURE:
    case OthersDeliveryActionsTypes.RETRIEVE_OTHERS_DELIVERY_FAILURE:
    case OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default othersDeliveryReducer;
