import { createSelector } from "reselect";

const selectVehicles = (state) => state.vehicles;

export const selectIsActionLoading = createSelector(
  [selectVehicles],
  (vehicles) => vehicles.actionLoading
);

export const selectAllVehicles = createSelector(
  [selectVehicles],
  (vehicles) => vehicles.vehicles
);

export const selectIsLoading = createSelector(
  [selectVehicles],
  (vehicles) => vehicles.loading
);

export const selectIsSuccessful = createSelector(
  [selectVehicles],
  (vehicles) => vehicles.isSuccessful
);

export const selectError = createSelector(
  [selectVehicles],
  (vehicles) => vehicles.error
);
