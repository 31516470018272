import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import {
  addExpenseTypeRestart,
  addExpenseTypeStart,
  updateExpenseTypeRestart,
  updateExpenseTypeStart,
} from "../../../../../../redux/expense-types/expense-types.actions";
import {
  selectAllExpenseTypes,
  selectIsActionLoading,
  selectIsSuccessful,
} from "../../../../../../redux/expense-types/expense-types.selectors";

const ExpenseTypesModal = ({
  addExpenseTypeRestart,
  addExpenseTypeStart,
  expenseTypes,
  data,
  error,
  isActionLoading,
  isEdit,
  isSuccessful,
  updateExpenseTypeRestart,
  updateExpenseTypeStart,
  visible,
  setIsEdit,
  setVisible,
}) => {
  const [codeCount, setCodeCount] = useState("001");

  useEffect(() => {
    if (expenseTypes.length > 0) {
      const expenseTypesCount = expenseTypes.length + 1;

      setCodeCount("00" + expenseTypesCount.toString());
    }
  }, [expenseTypes]);

  const onFinish = (values) => {
    const { code, name } = values;
    const id = document.querySelector(".id").value;

    if (!isEdit) {
      addExpenseTypeStart({ code, id, name });
    } else {
      updateExpenseTypeStart({ code, id, name });
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccessful) {
      setVisible(false);
      setIsEdit(false);
      addExpenseTypeRestart();
      return updateExpenseTypeRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        setIsEdit(false);
      }
    }
  }, [
    addExpenseTypeRestart,
    error,
    isSuccessful,
    updateExpenseTypeRestart,
    setIsEdit,
    setVisible,
  ]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Manage Expense Type Details"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish} preserve={false}>
        <Input
          type="hidden"
          readOnly
          value={isEdit ? data.id : ""}
          name="id"
          className="id"
        />
        <Form.Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please input code!",
            },
          ]}
          initialValue={isEdit ? data.code : codeCount}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Expense Type Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input expense type name!",
            },
          ]}
          initialValue={isEdit ? data.name : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isActionLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Update Expense Type" : "Add Expense Type"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  expenseTypes: selectAllExpenseTypes,
  isActionLoading: selectIsActionLoading,
  isSuccessful: selectIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  addExpenseTypeRestart: () => dispatch(addExpenseTypeRestart()),
  addExpenseTypeStart: (data) => dispatch(addExpenseTypeStart(data)),
  updateExpenseTypeRestart: () => dispatch(updateExpenseTypeRestart()),
  updateExpenseTypeStart: (data) => dispatch(updateExpenseTypeStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTypesModal);
