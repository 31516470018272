const ExpensesActionsTypes = {
  ADD_EXPENSE_START: "ADD_EXPENSE_START",
  ADD_EXPENSE_SUCCESS: "ADD_EXPENSE_SUCCESS",
  ADD_EXPENSE_FAILURE: "ADD_EXPENSE_FAILURE",
  ADD_EXPENSE_RESTART: "ADD_EXPENSE_RESTART",
  DELETE_EXPENSE_START: "DELETE_EXPENSE_START",
  DELETE_EXPENSE_SUCCESS: "DELETE_EXPENSE_SUCCESS",
  DELETE_EXPENSE_FAILURE: "DELETE_EXPENSE_FAILURE",
  DELETE_EXPENSE_RESTART: "DELETE_EXPENSE_RESTART",
  UPDATE_EXPENSE_START: "UPDATE_EXPENSE_START",
  UPDATE_EXPENSE_SUCCESS: "UPDATE_EXPENSE_SUCCESS",
  UPDATE_EXPENSE_FAILURE: "UPDATE_EXPENSE_FAILURE",
  UPDATE_EXPENSE_RESTART: "UPDATE_EXPENSE_RESTART",
  RETRIEVE_EXPENSES_START: "RETRIEVE_EXPENSES_START",
  RETRIEVE_EXPENSES_SUCCESS: "RETRIEVE_EXPENSES_SUCCESS",
  RETRIEVE_EXPENSES_FAILURE: "RETRIEVE_EXPENSES_FAILURE",
};

export default ExpensesActionsTypes;
