export const billingCategories = [
  "Billing DOB",
  "Billing DOB with Expense",
  "Billing Feeds",
  "Billing Growers",
  "Manage Other Billing",
];

export const maintenanceCategories = [
  "Customers",
  "Drivers",
  "Expenses",
  "Expense Types",
  "Farms",
  "Helpers",
  "Suppliers",
  "Vehicles | Trucking",
];

export const quickLinksCategories = [
  "DOB",
  "DOB with Expense",
  "Feeds",
  "Feeds Retrieval",
  "Growers",
  "Manage Other Delivery",
];

export const reportsCategories = [
  "Accounts Payables (A/P) Balance",
  "Delivery Productivity Report",
  "Income Statement",
  "Salary's Report",
  "Truck Productivity Report",
];

export const transactionsCategories = ["Maintenance DR's"];
