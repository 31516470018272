import React, { useEffect } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";

const UserProfileModal = ({
  error,
  isLoading,
  isUpdateEmailSuccessful,
  setVisible,
  updateEmailRestart,
  updateEmailStart,
  user,
  visible,
}) => {
  const onFinish = (values) => {
    const { email } = values;
    const { id, password } = user;
    const currentEmail = user.email;

    if (email === currentEmail) {
      return openNotificationError("There's nothing to update.");
    }

    updateEmailStart({
      email,
      id,
      password,
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const openNotificationSuccess = () => {
    notification.success({
      message: `Success!`,
      duration: 5,
      description: "Successfully updated your user profile.",
      placement: "topRight",
    });
  };

  const openNotificationError = (message) => {
    notification.error({
      message: `Uh-oh!`,
      duration: 5,
      description: message,
      placement: "topRight",
    });
  };

  useEffect(() => {
    if (isUpdateEmailSuccessful) {
      setVisible(false);
      openNotificationSuccess();
      return updateEmailRestart();
    } else {
      if (error !== null) {
        setVisible(false);
        return openNotificationError(error);
      }
    }
  }, [error, isUpdateEmailSuccessful, updateEmailRestart, setVisible]);

  return (
    <Modal
      footer={null}
      title="Settings"
      visible={visible}
      onCancel={handleCancel}
    >
      <Form {...layout} onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email field must not be blank.",
            },
          ]}
          initialValue={user ? user.email : ""}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 18 }}>
          <Button
            loading={isLoading}
            style={{
              borderRadius: 5,
              border: "none",
            }}
            type="primary"
            htmlType="submit"
          >
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserProfileModal;
