import VehiclesActionsTypes from "./vehicles.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  vehicles: [],
};

const vehiclesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VehiclesActionsTypes.RETRIEVE_VEHICLES_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case VehiclesActionsTypes.ADD_VEHICLE_START:
    case VehiclesActionsTypes.DELETE_VEHICLE_START:
    case VehiclesActionsTypes.UPDATE_VEHICLE_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case VehiclesActionsTypes.RETRIEVE_VEHICLES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        vehicles: action.payload,
      };
    case VehiclesActionsTypes.ADD_VEHICLE_SUCCESS:
    case VehiclesActionsTypes.DELETE_VEHICLE_SUCCESS:
    case VehiclesActionsTypes.UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case VehiclesActionsTypes.ADD_VEHICLE_RESTART:
    case VehiclesActionsTypes.DELETE_VEHICLE_RESTART:
    case VehiclesActionsTypes.UPDATE_VEHICLE_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case VehiclesActionsTypes.ADD_VEHICLE_FAILURE:
    case VehiclesActionsTypes.DELETE_VEHICLE_FAILURE:
    case VehiclesActionsTypes.RETRIEVE_VEHICLES_FAILURE:
    case VehiclesActionsTypes.UPDATE_VEHICLE_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default vehiclesReducer;
