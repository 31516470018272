import FarmsActionsTypes from "./farms.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  farms: [],
};

const farmsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FarmsActionsTypes.RETRIEVE_FARMS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FarmsActionsTypes.ADD_FARM_START:
    case FarmsActionsTypes.DELETE_FARM_START:
    case FarmsActionsTypes.UPDATE_FARM_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case FarmsActionsTypes.RETRIEVE_FARMS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        farms: action.payload,
      };
    case FarmsActionsTypes.ADD_FARM_SUCCESS:
    case FarmsActionsTypes.DELETE_FARM_SUCCESS:
    case FarmsActionsTypes.UPDATE_FARM_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case FarmsActionsTypes.ADD_FARM_RESTART:
    case FarmsActionsTypes.DELETE_FARM_RESTART:
    case FarmsActionsTypes.UPDATE_FARM_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case FarmsActionsTypes.ADD_FARM_FAILURE:
    case FarmsActionsTypes.DELETE_FARM_FAILURE:
    case FarmsActionsTypes.RETRIEVE_FARMS_FAILURE:
    case FarmsActionsTypes.UPDATE_FARM_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default farmsReducer;
