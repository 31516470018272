import GrowersActionsTypes from "./growers.types";

const INITIAL_STATE = {
  actionLoading: false,
  error: null,
  isSuccessful: false,
  loading: false,
  growers: [],
};

const growersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GrowersActionsTypes.RETRIEVE_GROWERS_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GrowersActionsTypes.ADD_GROWER_START:
    case GrowersActionsTypes.DELETE_GROWER_START:
    case GrowersActionsTypes.UPDATE_GROWER_START:
      return {
        ...state,
        error: null,
        actionLoading: true,
        isSuccessful: false,
      };
    case GrowersActionsTypes.RETRIEVE_GROWERS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        growers: action.payload,
      };
    case GrowersActionsTypes.ADD_GROWER_SUCCESS:
    case GrowersActionsTypes.DELETE_GROWER_SUCCESS:
    case GrowersActionsTypes.UPDATE_GROWER_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        error: null,
        isSuccessful: true,
      };
    case GrowersActionsTypes.ADD_GROWER_RESTART:
    case GrowersActionsTypes.DELETE_GROWER_RESTART:
    case GrowersActionsTypes.UPDATE_GROWER_RESTART:
      return {
        ...state,
        actionLoading: false,
        isSuccessful: false,
      };
    case GrowersActionsTypes.ADD_GROWER_FAILURE:
    case GrowersActionsTypes.DELETE_GROWER_FAILURE:
    case GrowersActionsTypes.RETRIEVE_GROWERS_FAILURE:
    case GrowersActionsTypes.UPDATE_GROWER_FAILURE:
      return {
        ...state,
        actionLoading: false,
        error: action.payload,
        isSuccessful: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default growersReducer;
