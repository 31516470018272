import DobsActionsTypes from "./dob.types";

export const addDobStart = (data) => ({
  type: DobsActionsTypes.ADD_DOB_START,
  payload: data,
});

export const addDobSuccess = () => ({
  type: DobsActionsTypes.ADD_DOB_SUCCESS,
});

export const addDobFailure = (error) => ({
  type: DobsActionsTypes.ADD_DOB_FAILURE,
  payload: error,
});

export const addDobRestart = () => ({
  type: DobsActionsTypes.ADD_DOB_RESTART,
});

export const deleteDobStart = (data) => ({
  type: DobsActionsTypes.DELETE_DOB_START,
  payload: data,
});

export const deleteDobSuccess = () => ({
  type: DobsActionsTypes.DELETE_DOB_SUCCESS,
});

export const deleteDobFailure = (error) => ({
  type: DobsActionsTypes.DELETE_DOB_FAILURE,
  payload: error,
});

export const deleteDobRestart = () => ({
  type: DobsActionsTypes.DELETE_DOB_RESTART,
});

export const retrieveDobsStart = () => ({
  type: DobsActionsTypes.RETRIEVE_DOBS_START,
});

export const retrieveDobsSuccess = (data) => ({
  type: DobsActionsTypes.RETRIEVE_DOBS_SUCCESS,
  payload: data,
});

export const retrieveDobsFailure = (error) => ({
  type: DobsActionsTypes.RETRIEVE_DOBS_FAILURE,
  payload: error,
});

export const updateDobStart = (data) => ({
  type: DobsActionsTypes.UPDATE_DOB_START,
  payload: data,
});

export const updateDobSuccess = () => ({
  type: DobsActionsTypes.UPDATE_DOB_SUCCESS,
});

export const updateDobFailure = (error) => ({
  type: DobsActionsTypes.UPDATE_DOB_FAILURE,
  payload: error,
});

export const updateDobRestart = () => ({
  type: DobsActionsTypes.UPDATE_DOB_RESTART,
});
