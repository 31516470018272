import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import DobExpensesActionsTypes from "./dob-expense.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addDobExpenseFailure,
  addDobExpenseSuccess,
  deleteDobExpenseFailure,
  deleteDobExpenseSuccess,
  retrieveDobExpensesFailure,
  retrieveDobExpensesSuccess,
  updateDobExpenseFailure,
  updateDobExpenseSuccess,
} from "./dob-expense.actions";

function* addDobExpense({
  payload: {
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    interest,
    minimumCapacity,
    remarks,
    rmsdNo,
    vehicleName,
  },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("dobExpenses").doc(id);

    yield reference.set({
      date: new Date().toLocaleDateString(),
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      interest,
      minimumCapacity,
      remarks,
      rmsdNo,
      vehicleName,
    });

    yield retrieveDobExpenses();
    yield put(addDobExpenseSuccess());
  } catch (error) {
    yield put(addDobExpenseFailure(error.message));
  }
}

function* deleteDobExpense({ payload: { id } }) {
  try {
    yield firestore.collection("dobExpenses").doc(id).delete();

    yield retrieveDobExpenses();
    yield put(deleteDobExpenseSuccess());
  } catch (error) {
    yield put(deleteDobExpenseFailure(error.message));
  }
}

function* retrieveDobExpenses() {
  try {
    const reference = yield firestore.collection("dobExpenses");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveDobExpensesSuccess([]));
    } else {
      let dobExpenses = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        dobExpenses.push(data);
      });

      yield put(retrieveDobExpensesSuccess(dobExpenses));
    }
  } catch (error) {
    yield put(retrieveDobExpensesFailure(error.message));
  }
}

function* updateDobExpense({
  payload: {
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    id,
    interest,
    minimumCapacity,
    remarks,
    rmsdNo,
    vehicleName,
  },
}) {
  try {
    const reference = yield firestore.collection("dobExpenses").doc(id);

    yield reference.update({
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      interest,
      minimumCapacity,
      remarks,
      rmsdNo,
      vehicleName,
    });

    yield retrieveDobExpenses();
    yield put(updateDobExpenseSuccess());
  } catch (error) {
    yield put(updateDobExpenseFailure(error.message));
  }
}

function* onAddDobExpenseStart() {
  yield takeLatest(
    DobExpensesActionsTypes.ADD_DOB_EXPENSE_START,
    addDobExpense
  );
}

function* onDeleteDobExpenseStart() {
  yield takeLatest(
    DobExpensesActionsTypes.DELETE_DOB_EXPENSE_START,
    deleteDobExpense
  );
}

function* onRetrieveDobExpensesStart() {
  yield takeLatest(
    DobExpensesActionsTypes.RETRIEVE_DOB_EXPENSES_START,
    retrieveDobExpenses
  );
}

function* onUpdateDobExpenseStart() {
  yield takeLatest(
    DobExpensesActionsTypes.UPDATE_DOB_EXPENSE_START,
    updateDobExpense
  );
}

export function* dobExpenseSaga() {
  yield all([
    call(onAddDobExpenseStart),
    call(onDeleteDobExpenseStart),
    call(onRetrieveDobExpensesStart),
    call(onUpdateDobExpenseStart),
  ]);
}
