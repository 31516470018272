import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectIsLoading = createSelector(
  [selectUser],
  (user) => user.loading
);

export const selectIsSuccessful = createSelector(
  [selectUser],
  (user) => user.isSuccessful
);

export const selectIsUpdateEmailSuccessful = createSelector(
  [selectUser],
  (user) => user.isUpdateEmailSuccessful
);

export const selectIsUpdateProfileSuccessful = createSelector(
  [selectUser],
  (user) => user.isUpdateProfileSuccessful
);

export const selectIsUpdatePasswordSuccessful = createSelector(
  [selectUser],
  (user) => user.isUpdatePasswordSuccessful
);

export const selectError = createSelector([selectUser], (user) => user.error);
