import { createSelector } from "reselect";

const selectSuppliers = (state) => state.suppliers;

export const selectIsActionLoading = createSelector(
  [selectSuppliers],
  (suppliers) => suppliers.actionLoading
);

export const selectAllSuppliers = createSelector(
  [selectSuppliers],
  (suppliers) => suppliers.suppliers
);

export const selectIsLoading = createSelector(
  [selectSuppliers],
  (suppliers) => suppliers.loading
);

export const selectIsSuccessful = createSelector(
  [selectSuppliers],
  (suppliers) => suppliers.isSuccessful
);

export const selectError = createSelector(
  [selectSuppliers],
  (suppliers) => suppliers.error
);
