import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import ExpenseTypesActionsTypes from "./expense-types.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addExpenseTypeFailure,
  addExpenseTypeSuccess,
  deleteExpenseTypeFailure,
  deleteExpenseTypeSuccess,
  retrieveExpenseTypesFailure,
  retrieveExpenseTypesSuccess,
  updateExpenseTypeFailure,
  updateExpenseTypeSuccess,
} from "./expense-types.actions";

function* addExpenseType({ payload: { code, name } }) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("expenseTypes").doc(id);

    yield reference.set({
      code,
      id,
      name,
    });

    yield retrieveExpenseTypes();
    yield put(addExpenseTypeSuccess());
  } catch (error) {
    yield put(addExpenseTypeFailure(error.message));
  }
}

function* deleteExpenseType({ payload: { id } }) {
  try {
    yield firestore.collection("expenseTypes").doc(id).delete();

    yield retrieveExpenseTypes();
    yield put(deleteExpenseTypeSuccess());
  } catch (error) {
    yield put(deleteExpenseTypeFailure(error.message));
  }
}

function* retrieveExpenseTypes() {
  try {
    const reference = yield firestore.collection("expenseTypes");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveExpenseTypesSuccess([]));
    } else {
      let expenseTypes = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        expenseTypes.push(data);
      });

      yield put(retrieveExpenseTypesSuccess(expenseTypes));
    }
  } catch (error) {
    yield put(retrieveExpenseTypesFailure(error.message));
  }
}

function* updateExpenseType({ payload: { code, id, name } }) {
  try {
    const reference = yield firestore.collection("expenseTypes").doc(id);

    yield reference.update({
      code,
      id,
      name,
    });

    yield retrieveExpenseTypes();
    yield put(updateExpenseTypeSuccess());
  } catch (error) {
    yield put(updateExpenseTypeFailure(error.message));
  }
}

function* onAddExpenseTypeStart() {
  yield takeLatest(
    ExpenseTypesActionsTypes.ADD_EXPENSE_TYPE_START,
    addExpenseType
  );
}

function* onDeleteExpenseTypeStart() {
  yield takeLatest(
    ExpenseTypesActionsTypes.DELETE_EXPENSE_TYPE_START,
    deleteExpenseType
  );
}

function* onRetrieveExpenseTypeStart() {
  yield takeLatest(
    ExpenseTypesActionsTypes.RETRIEVE_EXPENSE_TYPES_START,
    retrieveExpenseTypes
  );
}

function* onUpdateExpenseTypeStart() {
  yield takeLatest(
    ExpenseTypesActionsTypes.UPDATE_EXPENSE_TYPE_START,
    updateExpenseType
  );
}

export function* expenseTypesSaga() {
  yield all([
    call(onAddExpenseTypeStart),
    call(onDeleteExpenseTypeStart),
    call(onRetrieveExpenseTypeStart),
    call(onUpdateExpenseTypeStart),
  ]);
}
