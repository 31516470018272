const ExpenseTypesActionsTypes = {
  ADD_EXPENSE_TYPE_START: "ADD_EXPENSE_TYPE_START",
  ADD_EXPENSE_TYPE_SUCCESS: "ADD_EXPENSE_TYPE_SUCCESS",
  ADD_EXPENSE_TYPE_FAILURE: "ADD_EXPENSE_TYPE_FAILURE",
  ADD_EXPENSE_TYPE_RESTART: "ADD_EXPENSE_TYPE_RESTART",
  DELETE_EXPENSE_TYPE_START: "DELETE_EXPENSE_TYPE_START",
  DELETE_EXPENSE_TYPE_SUCCESS: "DELETE_EXPENSE_TYPE_SUCCESS",
  DELETE_EXPENSE_TYPE_FAILURE: "DELETE_EXPENSE_TYPE_FAILURE",
  DELETE_EXPENSE_TYPE_RESTART: "DELETE_EXPENSE_TYPE_RESTART",
  UPDATE_EXPENSE_TYPE_START: "UPDATE_EXPENSE_TYPE_START",
  UPDATE_EXPENSE_TYPE_SUCCESS: "UPDATE_EXPENSE_TYPE_SUCCESS",
  UPDATE_EXPENSE_TYPE_FAILURE: "UPDATE_EXPENSE_TYPE_FAILURE",
  UPDATE_EXPENSE_TYPE_RESTART: "UPDATE_EXPENSE_TYPE_RESTART",
  RETRIEVE_EXPENSE_TYPES_START: "RETRIEVE_EXPENSE_TYPES_START",
  RETRIEVE_EXPENSE_TYPES_SUCCESS: "RETRIEVE_EXPENSE_TYPES_SUCCESS",
  RETRIEVE_EXPENSE_TYPES_FAILURE: "RETRIEVE_EXPENSE_TYPES_FAILURE",
};

export default ExpenseTypesActionsTypes;
