const FarmsActionsTypes = {
  ADD_FARM_START: "ADD_FARM_START",
  ADD_FARM_SUCCESS: "ADD_FARM_SUCCESS",
  ADD_FARM_FAILURE: "ADD_FARM_FAILURE",
  ADD_FARM_RESTART: "ADD_FARM_RESTART",
  DELETE_FARM_START: "DELETE_FARM_START",
  DELETE_FARM_SUCCESS: "DELETE_FARM_SUCCESS",
  DELETE_FARM_FAILURE: "DELETE_FARM_FAILURE",
  DELETE_FARM_RESTART: "DELETE_FARM_RESTART",
  UPDATE_FARM_START: "UPDATE_FARM_START",
  UPDATE_FARM_SUCCESS: "UPDATE_FARM_SUCCESS",
  UPDATE_FARM_FAILURE: "UPDATE_FARM_FAILURE",
  UPDATE_FARM_RESTART: "UPDATE_FARM_RESTART",
  RETRIEVE_FARMS_START: "RETRIEVE_FARMS_START",
  RETRIEVE_FARMS_SUCCESS: "RETRIEVE_FARMS_SUCCESS",
  RETRIEVE_FARMS_FAILURE: "RETRIEVE_FARMS_FAILURE",
};

export default FarmsActionsTypes;
