import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import VehiclesActionsTypes from "./vehicles.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addVehicleFailure,
  addVehicleSuccess,
  deleteVehicleFailure,
  deleteVehicleSuccess,
  retrieveVehiclesFailure,
  retrieveVehiclesSuccess,
  updateVehicleFailure,
  updateVehicleSuccess,
} from "./vehicles.actions";

function* addVehicle({ payload: { code, name, plateNumber } }) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("vehicles").doc(id);

    yield reference.set({ code, id, name, plateNumber });

    yield retrieveVehicles();
    yield put(addVehicleSuccess());
  } catch (error) {
    yield put(addVehicleFailure(error.message));
  }
}

function* deleteVehicle({ payload: { id } }) {
  try {
    yield firestore.collection("vehicles").doc(id).delete();

    yield retrieveVehicles();
    yield put(deleteVehicleSuccess());
  } catch (error) {
    yield put(deleteVehicleFailure(error.message));
  }
}

function* retrieveVehicles() {
  try {
    const reference = yield firestore.collection("vehicles");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveVehiclesSuccess([]));
    } else {
      let vehicles = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        vehicles.push(data);
      });

      yield put(retrieveVehiclesSuccess(vehicles));
    }
  } catch (error) {
    yield put(retrieveVehiclesFailure(error.message));
  }
}

function* updateVehicle({ payload: { code, id, name, plateNumber } }) {
  try {
    const reference = yield firestore.collection("vehicles").doc(id);

    yield reference.update({ code, id, name, plateNumber });

    yield retrieveVehicles();
    yield put(updateVehicleSuccess());
  } catch (error) {
    yield put(updateVehicleFailure(error.message));
  }
}

function* onAddVehicleStart() {
  yield takeLatest(VehiclesActionsTypes.ADD_VEHICLE_START, addVehicle);
}

function* onDeleteVehicleStart() {
  yield takeLatest(VehiclesActionsTypes.DELETE_VEHICLE_START, deleteVehicle);
}

function* onRetrieveVehicleStart() {
  yield takeLatest(
    VehiclesActionsTypes.RETRIEVE_VEHICLES_START,
    retrieveVehicles
  );
}

function* onUpdateVehicleStart() {
  yield takeLatest(VehiclesActionsTypes.UPDATE_VEHICLE_START, updateVehicle);
}

export function* vehiclesSaga() {
  yield all([
    call(onAddVehicleStart),
    call(onDeleteVehicleStart),
    call(onRetrieveVehicleStart),
    call(onUpdateVehicleStart),
  ]);
}
