import UserActionTypes from "./user.types";

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: error,
});

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
});

export const signUpSuccess = ({ user, additionalData }) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: { user, additionalData },
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});

export const updateEmailStart = (email) => ({
  type: UserActionTypes.UPDATE_EMAIL_START,
  payload: email,
});

export const updateEmailSuccess = () => ({
  type: UserActionTypes.UPDATE_EMAIL_SUCCESS,
});

export const updateEmailFailure = (error) => ({
  type: UserActionTypes.UPDATE_EMAIL_FAILURE,
  payload: error,
});

export const updateEmailRestart = () => ({
  type: UserActionTypes.UPDATE_EMAIL_RESTART,
});

export const updateProfileStart = (userCredentials) => ({
  type: UserActionTypes.UPDATE_PROFILE_START,
  payload: userCredentials,
});

export const updateProfileSuccess = () => ({
  type: UserActionTypes.UPDATE_PROFILE_SUCCESS,
});

export const updateProfileFailure = (error) => ({
  type: UserActionTypes.UPDATE_PROFILE_FAILURE,
  payload: error,
});

export const updateProfileRestart = () => ({
  type: UserActionTypes.UPDATE_PROFILE_RESTART,
});

export const updatePasswordStart = (password) => ({
  type: UserActionTypes.UPDATE_PASSWORD_START,
  payload: password,
});

export const updatePasswordSuccess = () => ({
  type: UserActionTypes.UPDATE_PASSWORD_SUCCESS,
});

export const updatePasswordRestart = () => ({
  type: UserActionTypes.UPDATE_PASSWORD_RESTART,
});

export const updatePasswordFailure = (error) => ({
  type: UserActionTypes.UPDATE_PASSWORD_FAILURE,
  payload: error,
});

export const userRestart = () => ({
  type: UserActionTypes.USER_RESTART,
});
