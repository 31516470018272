const CustomersActionsTypes = {
  ADD_CUSTOMER_START: "ADD_CUSTOMER_START",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
  ADD_CUSTOMER_FAILURE: "ADD_CUSTOMER_FAILURE",
  ADD_CUSTOMER_RESTART: "ADD_CUSTOMER_RESTART",
  DELETE_CUSTOMER_START: "DELETE_CUSTOMER_START",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",
  DELETE_CUSTOMER_RESTART: "DELETE_CUSTOMER_RESTART",
  UPDATE_CUSTOMER_START: "UPDATE_CUSTOMER_START",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",
  UPDATE_CUSTOMER_RESTART: "UPDATE_CUSTOMER_RESTART",
  RETRIEVE_CUSTOMERS_START: "RETRIEVE_CUSTOMERS_START",
  RETRIEVE_CUSTOMERS_SUCCESS: "RETRIEVE_CUSTOMERS_SUCCESS",
  RETRIEVE_CUSTOMERS_FAILURE: "RETRIEVE_CUSTOMERS_FAILURE",
};

export default CustomersActionsTypes;
