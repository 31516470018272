import FeedsRetrievalsActionsTypes from "./feeds-retrieval.types";

export const addFeedsRetrievalStart = (data) => ({
  type: FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_START,
  payload: data,
});

export const addFeedsRetrievalSuccess = () => ({
  type: FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_SUCCESS,
});

export const addFeedsRetrievalFailure = (error) => ({
  type: FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_FAILURE,
  payload: error,
});

export const addFeedsRetrievalRestart = () => ({
  type: FeedsRetrievalsActionsTypes.ADD_FEEDS_RETRIEVAL_RESTART,
});

export const deleteFeedsRetrievalStart = (data) => ({
  type: FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_START,
  payload: data,
});

export const deleteFeedsRetrievalSuccess = () => ({
  type: FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_SUCCESS,
});

export const deleteFeedsRetrievalFailure = (error) => ({
  type: FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_FAILURE,
  payload: error,
});

export const deleteFeedsRetrievalRestart = () => ({
  type: FeedsRetrievalsActionsTypes.DELETE_FEEDS_RETRIEVAL_RESTART,
});

export const retrieveFeedsRetrievalsStart = () => ({
  type: FeedsRetrievalsActionsTypes.RETRIEVE_FEEDS_RETRIEVALS_START,
});

export const retrieveFeedsRetrievalsSuccess = (data) => ({
  type: FeedsRetrievalsActionsTypes.RETRIEVE_FEEDS_RETRIEVALS_SUCCESS,
  payload: data,
});

export const retrieveFeedsRetrievalsFailure = (error) => ({
  type: FeedsRetrievalsActionsTypes.RETRIEVE_FEEDS_RETRIEVALS_FAILURE,
  payload: error,
});

export const updateFeedsRetrievalStart = (data) => ({
  type: FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_START,
  payload: data,
});

export const updateFeedsRetrievalSuccess = () => ({
  type: FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_SUCCESS,
});

export const updateFeedsRetrievalFailure = (error) => ({
  type: FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_FAILURE,
  payload: error,
});

export const updateFeedsRetrievalRestart = () => ({
  type: FeedsRetrievalsActionsTypes.UPDATE_FEEDS_RETRIEVAL_RESTART,
});
