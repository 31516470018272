import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import GrowersActionsTypes from "./growers.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addGrowerFailure,
  addGrowerSuccess,
  deleteGrowerFailure,
  deleteGrowerSuccess,
  retrieveGrowersFailure,
  retrieveGrowersSuccess,
  updateGrowerFailure,
  updateGrowerSuccess,
} from "./growers.actions";

function* addGrower({
  payload: {
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    minimumCapacity,
    remarks,
    rsNo,
    vehicleName,
  },
}) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("growers").doc(id);

    yield reference.set({
      date: new Date().toLocaleDateString(),
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      minimumCapacity,
      remarks,
      rsNo,
      vehicleName,
    });

    yield retrieveGrowers();
    yield put(addGrowerSuccess());
  } catch (error) {
    yield put(addGrowerFailure(error.message));
  }
}

function* deleteGrower({ payload: { id } }) {
  try {
    yield firestore.collection("growers").doc(id).delete();

    yield retrieveGrowers();
    yield put(deleteGrowerSuccess());
  } catch (error) {
    yield put(deleteGrowerFailure(error.message));
  }
}

function* retrieveGrowers() {
  try {
    const reference = yield firestore.collection("growers");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveGrowersSuccess([]));
    } else {
      let growers = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        growers.push(data);
      });

      yield put(retrieveGrowersSuccess(growers));
    }
  } catch (error) {
    yield put(retrieveGrowersFailure(error.message));
  }
}

function* updateGrower({
  payload: {
    drDate,
    driverName,
    driverSalary,
    farmName,
    helpers,
    id,
    minimumCapacity,
    remarks,
    rsNo,
    vehicleName,
  },
}) {
  try {
    const reference = yield firestore.collection("growers").doc(id);

    yield reference.update({
      drDate,
      driverName,
      driverSalary,
      farmName,
      helpers,
      id,
      minimumCapacity,
      remarks,
      rsNo,
      vehicleName,
    });

    yield retrieveGrowers();
    yield put(updateGrowerSuccess());
  } catch (error) {
    yield put(updateGrowerFailure(error.message));
  }
}

function* onAddGrowerStart() {
  yield takeLatest(GrowersActionsTypes.ADD_GROWER_START, addGrower);
}

function* onDeleteGrowerStart() {
  yield takeLatest(GrowersActionsTypes.DELETE_GROWER_START, deleteGrower);
}

function* onRetrieveGrowersStart() {
  yield takeLatest(GrowersActionsTypes.RETRIEVE_GROWERS_START, retrieveGrowers);
}

function* onUpdateGrowerStart() {
  yield takeLatest(GrowersActionsTypes.UPDATE_GROWER_START, updateGrower);
}

export function* growersSaga() {
  yield all([
    call(onAddGrowerStart),
    call(onDeleteGrowerStart),
    call(onRetrieveGrowersStart),
    call(onUpdateGrowerStart),
  ]);
}
