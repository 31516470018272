import { takeLatest, put, all, call } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import ExpensesActionsTypes from "./expenses.types";
import { firestore } from "../../firebase/firebase.utils";
import {
  addExpenseFailure,
  addExpenseSuccess,
  deleteExpenseFailure,
  deleteExpenseSuccess,
  retrieveExpensesFailure,
  retrieveExpensesSuccess,
  updateExpenseFailure,
  updateExpenseSuccess,
} from "./expenses.actions";

function* addExpense({ payload: { code, counter, name, type } }) {
  try {
    const id = uuidv4();
    const reference = yield firestore.collection("expenses").doc(id);

    yield reference.set({ code, counter, id, name, type });

    yield retrieveExpenses();
    yield put(addExpenseSuccess());
  } catch (error) {
    yield put(addExpenseFailure(error.message));
  }
}

function* deleteExpense({ payload: { id } }) {
  try {
    yield firestore.collection("expenses").doc(id).delete();

    yield retrieveExpenses();
    yield put(deleteExpenseSuccess());
  } catch (error) {
    yield put(deleteExpenseFailure(error.message));
  }
}

function* retrieveExpenses() {
  try {
    const reference = yield firestore.collection("expenses");
    const snapshot = yield reference.get();

    if (snapshot.empty) {
      yield put(retrieveExpensesSuccess([]));
    } else {
      let expenses = [];

      snapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id,
          key: doc.id,
        };

        expenses.push(data);
      });

      yield put(retrieveExpensesSuccess(expenses));
    }
  } catch (error) {
    yield put(retrieveExpensesFailure(error.message));
  }
}

function* updateExpense({ payload: { code, counter, id, name, type } }) {
  try {
    const reference = yield firestore.collection("expenses").doc(id);

    yield reference.update({ code, counter, id, name, type });

    yield retrieveExpenses();
    yield put(updateExpenseSuccess());
  } catch (error) {
    yield put(updateExpenseFailure(error.message));
  }
}

function* onAddExpenseStart() {
  yield takeLatest(ExpensesActionsTypes.ADD_EXPENSE_START, addExpense);
}

function* onDeleteExpenseStart() {
  yield takeLatest(ExpensesActionsTypes.DELETE_EXPENSE_START, deleteExpense);
}

function* onRetrieveExpensesStart() {
  yield takeLatest(
    ExpensesActionsTypes.RETRIEVE_EXPENSES_START,
    retrieveExpenses
  );
}

function* onUpdateExpenseStart() {
  yield takeLatest(ExpensesActionsTypes.UPDATE_EXPENSE_START, updateExpense);
}

export function* expensesSaga() {
  yield all([
    call(onAddExpenseStart),
    call(onDeleteExpenseStart),
    call(onRetrieveExpensesStart),
    call(onUpdateExpenseStart),
  ]);
}
