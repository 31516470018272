import OthersDeliveryActionsTypes from "./others-delivery.types";

export const addOthersDeliveryStart = (data) => ({
  type: OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_START,
  payload: data,
});

export const addOthersDeliverySuccess = () => ({
  type: OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_SUCCESS,
});

export const addOthersDeliveryFailure = (error) => ({
  type: OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_FAILURE,
  payload: error,
});

export const addOthersDeliveryRestart = () => ({
  type: OthersDeliveryActionsTypes.ADD_OTHERS_DELIVERY_RESTART,
});

export const deleteOthersDeliveryStart = (data) => ({
  type: OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_START,
  payload: data,
});

export const deleteOthersDeliverySuccess = () => ({
  type: OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_SUCCESS,
});

export const deleteOthersDeliveryFailure = (error) => ({
  type: OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_FAILURE,
  payload: error,
});

export const deleteOthersDeliveryRestart = () => ({
  type: OthersDeliveryActionsTypes.DELETE_OTHERS_DELIVERY_RESTART,
});

export const retrieveOthersDeliveryStart = () => ({
  type: OthersDeliveryActionsTypes.RETRIEVE_OTHERS_DELIVERY_START,
});

export const retrieveOthersDeliverySuccess = (data) => ({
  type: OthersDeliveryActionsTypes.RETRIEVE_OTHERS_DELIVERY_SUCCESS,
  payload: data,
});

export const retrieveOthersDeliveryFailure = (error) => ({
  type: OthersDeliveryActionsTypes.RETRIEVE_OTHERS_DELIVERY_FAILURE,
  payload: error,
});

export const updateOthersDeliveryStart = (data) => ({
  type: OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_START,
  payload: data,
});

export const updateOthersDeliverySuccess = () => ({
  type: OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_SUCCESS,
});

export const updateOthersDeliveryFailure = (error) => ({
  type: OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_FAILURE,
  payload: error,
});

export const updateOthersDeliveryRestart = () => ({
  type: OthersDeliveryActionsTypes.UPDATE_OTHERS_DELIVERY_RESTART,
});
